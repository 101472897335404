@CHARSET "UTF-8";
/* --------------------------- PM.PHP ---------------------------- */
#controls {
    border: 1px solid #CCCCCC;
    border-bottom: none;
    overflow: hidden;
    height: 31px;
    position: relative;
    background-color: #f5f5f5;
}

#controls a:hover {
    color: #7b9cff;
}

#fbu_wrap,
#filters {
    overflow: hidden;
    height: 31px;
}

#add_contact_link {
    float: left;
    cursor: pointer;
    line-height: 31px;
}

.fa * {
    vertical-align: middle;
}

#new_contact_wrap {
    padding: 0 10px;
    text-align: right;
    float: right;
    min-width: 31px;
}

#fbu_wrap {
    width: 127px;
    position: relative;
    float: left;
}

#new_contact {
    margin: 3px 0 0 8px;
    display: none;
    width: 0px;
    float: left;
    height: 17px;
}

#find_contact {
    left: 104px;
    position: absolute;
    top: 7px;
    color: #669FFC;
    font-size: 17px;
    padding-left: 3px;
    border-left: 1px solid #ddd;
    cursor: pointer;
}

#find_contact:hover {
    color: blue;
}

.mark_wrap {
    border-right: 1px solid #CCCCCC;
    width: 120px;
    height: 31px;
    text-align: center;
    float: right;
    line-height: 31px;
    padding-right: 5px;
}

#change_view_wrap {
    width: 150px;
    height: 31px;
    text-align: left;
    float: left;
    line-height: 31px;
    margin-left: 5px;
}

#paged_view,
#chat_view {
    line-height: 27px;
    vertical-align: middle;
    cursor: pointer;
}

#pm_top_row #chat_view {
    font-size: 18px;
    line-height: 31px;
}

#paged_view {
    font-size: 18px;
    margin-top: -1px;
}

#chat_view {
    font-size: 16px;
}

#change_view_wrap .fa.active,
#change_view_wrap .fa.active:hover {
    color: green;
    cursor: default;
}

#change_view_wrap .fa:hover {
    color: #0000FF;
}

#filters {
    line-height: 31px;
    border-left: none;
    float: left;
    width: 115px;
    border-right: 1px solid #CCCCCC;
    text-align: center;
    color: #999;
}

#close_thread_wrap {
    float: left;
    height: 31px;
    line-height: 31px;
    text-align: center;
    display: none;
    border-right: 1px solid #ccc;
    padding: 0 10px 0 5px;
}

#close_thread_wrap.visible {
    display: block;
}

#username_filter {
    width: 99px;
    padding-right: 19px;
    margin-left: 4px;
    margin-top: 3px;
    height: 17px;
}

#threads_main {
    background-color: #ccc;
    position: relative;
    padding: 1px;
}

#threads_left,
#threads_right {
    overflow-y: auto;
}

#threads_right {
    display: none;
    position: absolute;
    left: 244px;
    right: 0;
    margin: 0 1px 1px 0;
    background-color: white;
}

#threads_left {
    background-color: #FFFFFF;
}

.th_wrap {
    background-color: white;
    border: 4px solid transparent;
    height: 54px;
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
    position: relative;
    padding: 8px;
    cursor: pointer;
}

.pm_thread.new .th_wrap {
    background: #ffdbec;
}

.pm_thread,
#load_more_conversations {
    border-bottom: 1px solid #ccc;
}

.pm_thread.active .th_wrap {
    background-color: #d7eaff;
    border-color: #d7eaff;
}

.th_wrap:hover,
.pm_thread.new .th_wrap:hover {
    background-color: #eee;
    border-color: #eee;
}

.pm-ctrl {
    color: #999;
    cursor: pointer;
    opacity: 0.3;
}

.pm-ctrl:hover {
    opacity: 1;
}

.th_user_thumb,
.th_user_info,
.th_new,
.th_snippet,
.th_controls {
    height: 55px;
    position: absolute;
    overflow: hidden;
}

.th_snippet.pm_new {
    background: #ffebf0;
    border: 1px solid #ffd1dd;
}

#threads_right_bottom {
    display: none;
}

/* Thread row = 79 px threads_main height = 79 * count rows + 1px */
/* Default 6 rows; */
#threads_main.full #threads_right_bottom {
    bottom: 0;
    display: block;
    left: 244px;
    position: absolute;
    background-color: #FFFFFF;
    right: 0;
    overflow: hidden;
    margin: 1px 1px 1px 0;
    height: 45px;
    background: #eee;
}

#threads_main.full,
#threads_main.full #threads_left {
    height: 396px; /* change also in pm.js threads_main_resize() */
    max-height: none;
}

#threads_main,
#threads_left {
    max-height: 442px; /* change also in pm.js threads_main_resize() */
}

/* #threads_main.height - #threads_main.full #threads_right_bottom.height */
#threads_main.full #threads_right {
    height: 350px;; /* change also in pm.js threads_main_resize() */
}

/* #threads_main.height - #threads_main.full.send #threads_right_bottom.height */
#threads_main.full.send #threads_right {
    height: 259px; /* change also in pm.js threads_main_resize() */
}

#threads_main.full.send #message {
    height: 130px;
}

#threads_main.full.send.big_msg #message {
    height: 270px;
}

#threads_main.full.send #threads_right_bottom {
    height: 180px;
}

#threads_main.full.send.big_msg #threads_right_bottom {
    height: 320px;
}

#threads_main.full.send #msg_submit {
    top: 1px;
}

#threads_main.full .th_snippet {
    display: none;
}

#threads_main.full #threads_left {
    width: 242px;
    position: absolute;
    left: 1px;
}

#threads_main.full #threads_right {
    display: block;
}

#threads_main.paged,
#threads_main.paged #threads_left {
    max-height: none;
}

.th_user_thumb {
    width: 50px;
    left: 10px;
    padding-top: 2px;
    overflow: visible;
}

.th_user_info {
    width: 120px;
    overflow: hidden;
    word-wrap: break-word;
    line-height: 18px;
    left: 70px; /* First col width */
}

.th_snippet {
    /* variable width */
    display: block;
    text-decoration: none;
    left: 210px;
    right: 40px;
    padding-left: 60px;
    line-height: 55px;
}

.snippet_thumb {
    height: 30px;
    width: 30px;
    top: 12px;
    left: 15px;
    position: absolute;
}

.th_controls {
    font-size: 20px;
    width: 30px;
    text-align: center;
    right: 0;
}

.th_controls .fa-ban {
    margin-top: 11px;
}

.pm_thread .fa-times.pm-ctrl {
    margin-top: 2px;
}

.circle_base {
    -webkit-border-radius: 999px;
    -moz-border-radius: 999px;
    border-radius: 999px;
}

.pm_new_circle {
    background: #ff738f;
    position: absolute;
    left: -8px;
    top: 40px;
    font-family: verdana;
    color: #fff;
    padding: 1px 5px;

    border-radius: 5px;
    border: 1px solid #fff;
    text-align: center;
    /*
    animation-name: bgani;
    animation-duration: 3s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;

    -webkit-animation-name: bgani;
    -webkit-animation-duration: 3s;
    -webkit-animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    */
}

/* For > +99 */
.pm_new_circle.small {
    font-size: 9px;
}

/*
@-moz-keyframes bgani {
   0%   {background: #FFDEDE;}
   25%  {color:red;}
   50%  {background: #FFA8A8; color: #333}
   75%  {color: red;}
   100% {background: #FFDEDE;}
}
@-webkit-keyframes bgani {
   0%   {background: #FFDEDE;}
   25%  {color:red;}
   50%  {background: #FFA8A8; color: #333}
   75%  {color: red;}
   100% {background: #FFDEDE;}
}
@keyframes bgani {
   0%   {background: #FFDEDE;}
   25%  {color:red;}
   50%  {background: #FFA8A8; color: #333}
   75%  {color: red;}
   100% {background: #FFDEDE;}
}
*/
.pm_new_cnt {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
}

#load_older_messages,
#load_more_conversations .th_wrap {
    font-size: 13px;
    color: #6088C8;
    cursor: pointer;
    line-height: 18px;
    text-align: center;
    height: 20px;
}

#load_older_messages {
    margin-bottom: -5px;
    margin-top: 5px;
}

#load_more_conversations {
    border-bottom: 1px solid #CCCCCC;
}

#load_more_conversations:hover,
#load_older_messages:hover {
    color: #3b5aff;
}

.no_messages {
    text-align: center;
}

.msg_user_thumb {
    height: 30px;
    float: left;
    margin-left: 7px;
    margin-right: 0px;
}

.msg_user_thumb .thumb {
    height: 30px;
    width: 30px;
    margin-top: 2px;
}

.msg_user {
    font-weight: bold;
    padding-left: 50px;
    margin-bottom: 3px;
}

.msg_user a.male {
    color: #06C;
}

.msg_body {
    margin: 0 110px 0 45px;
    padding: 3px 5px;
    font-family: Tahoma, Arial, Verdana, sans-serif, 'Lucida Sans';
    line-height: 16px;
    font-size: 12px;
    min-width: 350px;
    word-wrap: break-word;
    overflow-x: hidden;
}

.msg_body IMG {
    max-width: 480px;
}

/*.pm_unread .msg_body, .pm_new .msg_body {*/
.pm_new .msg_body, .pm_unread .msg_body {
    background: #ffebf0;
    border: 1px solid #ffd1dd;
}

.msg_last_online {
    color: #999;
    max-width: 650px;
    margin: auto;
}

.msg_last_online_inner {
    padding: 5px 0 5px 50px;
}

.thumb_link {
    padding-right: 3px;
    float: left;
}

.fl {
    float: left;
}

.fr {
    float: right;
}

.pm_msg {
    max-width: 650px;
    margin: auto;
    margin-bottom: 10px;
}

#single_thread_wrapper .pm_msg {
    border-bottom: 1px dotted #BBBBBB;
}

.msg_buttons {
    float: right;
    clear: right;
    margin-right: 3px;
}

.msg_buttons .fa-warning {
    font-size: 15px;
    margin: 3px 5px 0 0;
}

.msg_buttons .fa-times {
    font-size: 20px;
}

.msg_buttons .cog-rot {
    color: #808080;
    font-size: 21px;
    margin: -6px 6px 0 0;
    line-height: 31px;
    -moz-animation: spin 4s linear infinite;
    -webkit-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite;
}

.fa-1 {
    font-size: 14px;
}

.pm_time {
    color: #999;
    float: left;
    margin-top: 3px;
    margin-right: 5px;
    padding-left: 5px;
}

.msg_buttons_placeholder {
    visibility: hidden;
}

.msg_read_status, .msg_read_status_placeholder {
    font-size: 16px;
    margin-top: 3px;
    margin-right: 5px;
    width: 16px;
    height: 16px;
}

/*
.pm_unread .msg_read_status.read {
    display: none;
}
.pm_unread .msg_read_status.unread {
    display: block;
}
.pm_msg:not(.pm_unread) .msg_read_status.read {
    display: block;
}
.pm_msg:not(.pm_unread) .msg_read_status.unread {
    display: none;
}
*/
.msg_read_status_placeholder {
    visibility: hidden;
}

@-moz-keyframes rot {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes rot {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes rot {
    100% {
        transform: rotate(360deg);
    }
}

.msg_buttons i {
    display: block;
    float: left;
}

.pm_date_div {
    text-align: center;
    padding: 0 15px;
    min-width: 350px;
    margin: 15px 30px 0 30px;
}

.pm_date_div hr {
    height: 1px;
}

.pm_date {
    margin: 0 auto;
    background-color: white;
    margin-top: -12px;
    width: 80px;
    padding: 0 5px;
    color: #888;
}

#threads_right_bottom .markItUp,
#threads_right_bottom .markItUpContainer {
    margin: 0;
    border: 0;
    padding: 0;
}

#threads_right_bottom .markItUp #message {
    height: 25px;
    resize: none;
}

#threads_right_bottom .markItUpHeader {
    display: none;
}

#threads_main.send .markItUpHeader {
    display: inline-block;
    margin: 3px 0;
}

#threads_right_bottom .markItUpEditor {
    border: 0;
    width: 100%;
    background: #fff;
    font-family: "Verdana";
    letter-spacing: 1px;
    border: 1px solid #ccc;
}

#threads_right_bottom #markItUpMessage {
    left: 5px;
    top: 5px;
    position: absolute;
    right: 83px;
    width: auto;
}

#threads_main.send #threads_right_bottom #markItUpMessage {
    right: 15px;
}

#threads_right_bottom #msg_submit {
    position: absolute;
    right: 5px;
    top: 5px;
}

#threads_main .markItUp .palette ul {
    width: 162px;
}

.mod .male {
    font-weight: bold;
    color: #ff5d61;
}

/* .pm_new .pm_time:after {
    content: ' - NEW';
    color: green;
    font-weight: bold;
} */

/* .pm_unread .pm_time:after {
    content: ' - UNREAD';
    color: red;
    font-weight: bold;
} */
A.filter_active {
    color: #666;
}

#markitup_msg {
    color: grey;
    font-size: 11px;
    position: absolute;
    right: 85px;
    top: 8px;
    display: none;
}

#threads_main.send #markitup_msg {
    display: block;
}

#single_thread_wrapper #markitup_msg {
    display: inline;
    padding-right: 15px;
    position: relative;
    top: auto;
    right: auto;
}

#single_thread_wrapper .msg_body {
    max-width: 420px;
    margin-bottom: 5px;
}

.tui_username {
    float: left;
    width: 120px;
    overflow: hidden;
    height: 15px;
    line-height: 11px;
}

.tui_flag {
    float: left;
    padding-top: 2px;
    width: 17px;
    margin-right: 2px;
}

.tui_check {
    text-align: center;
    float: left;
    width: 17px;
    margin-right: 2px;
}

.tui_el {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100px;
    display: block;
    height: 16px;
}

.tui_last_time {
    color: #888;
    clear: left;
    margin-left: 19px;
}

.tui_age {
    color: #888;
}

.pm_pagination {
    padding: 10px;
    text-align: center;
    font-size: 15px;
}

.pm_pagination a.active {
    color: blue;
    font-weight: bold;
}

#markItUpPm_message {
    width: auto;
}

#compose_form_wrap {
    padding: 0 20px;
}

#pm_message {
    width: 97%;
}

.grey {
    color: grey;
}

#single_thread_outer_wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
}

#single_thread_wrapper {
    padding: 0 10px;
    width: 550px;
}

#single_thread_wrapper .msg_body {
    clear: both;
    margin: 10px 0;
    max-width: 95%;
}

#pm_top_row {
    line-height: 32px;
    height: 32px;
}

@media (min-width: 1100px) {
    #pm_top_row {
        padding: 0 20px;
    }
}

#pm_top_row .thumb {
    height: 30px;
    width: 30px;
}

#conversation {
    border-top: 1px dotted #BBBBBB;
    padding-top: 10px;
}

.empty_conv {
    margin: 10px;
    padding: 10px;
    background: #e3f1ff;
}
