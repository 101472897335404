.feedBody {
    margin-top: 10px;
}

.feedDay {
    font-weight: bold;
    color: #3493B5;
    padding: 2px;
}

.feedItem {
    padding: 2px;
    margin: 0 0 12px 0;
    overflow: hidden;
    color: #555;
    clear: left;
    border-bottom: 1px solid #ddd;
}

.feedItemContent {
    float: left;
    width: 300px;
    padding-bottom: 10px;
}

.feedItem hr {
    height: 1px;
    clear: both;
}

.feedItem .photo_prof {
    width: 65px;
    height: 70px;
}

.feedItem .photo_prof .user_info {
    width: 65px;
}

.feedItemDesc {
    clear: left;
}

.feedAlbTh {
    overflow: hidden;
    margin-top: 4px;
    float: left;
}

.feedAlbThRow {
    clear: left;
}

.feedAlbTh a {
    display: block;
    padding: 3px;
    overflow: hidden;
    border: 1px solid #ddd;
    float: left;
    margin: 3px;
}

.feedAlbTh a:hover {
    border: 1px solid #64BCDB;
}

.feed {
    width: 355px;
    float: left;
    overflow: hidden;
}

.feed .boxHead, .feed .boxHead h1 {
    background: #45D089;
    width: 300px;
}

.feed .boxHead {
    width: 330px;
}

.feedUserThumb {
    float: left;
    width: 35px;
    height: 35px;
    text-align: right;
    padding-right: 10px;
    color: #48a2f6;
}

.feedUserThumb img {
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
}

.feedUserStatus {
    /*color: #498c6e; */
    font-size: 13px;
    margin-top: 5px;
}

.feedUpdatedTxt {
    color: #999;
    font-size: 11px;
    display: block;
}

.feedUsername {
    font-size: 12px;
    font-weight: bold;
}
