/* -------------------------------------------------------------------
// markItUp! Universal MarkUp Engine, JQuery plugin
// By Jay Salvat - http://markitup.jaysalvat.com/
// ------------------------------------------------------------------*/
.markItUp * {
    margin: 0px;
    padding: 0px;
    outline: none;
}

.markItUp a:link,
.markItUp a:visited {
    color: #000;
    text-decoration: none;
}

.markItUp {
    width: 425px;
    margin: 5px 0 5px 0;
    border: 5px solid #F5F5F5;
}

.markItUpContainer {
    border: 1px solid #96C4FB;
    padding: 5px 5px 2px 5px;
    font: 11px Tahoma, Helvetica, sans-serif, Arial;
}

.markItUpEditor {
    font: 12px Tahoma, Helvetica, sans-serif, Arial;
    padding: 5px 5px 5px 5px;
    border: 3px solid #96C4FB;
    width: 95%;
    height: 320px;
    background: #FFF url(@public/images/markitup/bg-editor.png) repeat-x;
    clear: both;
    display: block;
    line-height: 18px;
    overflow: auto;
}

.markItUpEditor.MsgTAClp {
    height: 50px;
}

.markItUpPreviewFrame {
    overflow: auto;
    background-color: #FFFFFF;
    border: 1px solid #96C4FB;
    width: 99.9%;
    height: 300px;
    margin: 5px 0;
}

.markItUpFooter {
    width: 100%;
    cursor: n-resize;
}

.markItUpResizeHandle {
    overflow: hidden;
    width: 22px;
    height: 5px;
    margin-left: auto;
    margin-right: auto;
    background-image: url(@public/images/markitup/handle.png);
    cursor: n-resize;
}

/*****************************************/
/* first row of buttons */
.markItUpHeader ul li {
    list-style: none;
    float: left;
    position: relative;
}

.markItUpHeader ul li ul {
    display: none;
}

.markItUpHeader ul li:hover > ul {
    display: block;
}

.markItUpHeader ul .markItUpDropMenu {
    background: transparent url(@public/images/markitup/menu.png) no-repeat 115% 50%;
    margin-right: 5px;
}

.markItUpHeader ul .markItUpDropMenu li {
    margin-right: 0px;
}

.markItUpHeader ul .markItUpSeparator {
    margin: 0 10px;
    width: 1px;
    height: 16px;
    overflow: hidden;
    background-color: #CCC;
}

.markItUpHeader ul ul .markItUpSeparator {
    width: auto;
    height: 1px;
    margin: 0px;
}

/* next rows of buttons */
.markItUpHeader ul ul {
    display: none;
    position: absolute;
    top: 18px;
    left: 0px;
    background: #F5F5F5;
    border: 1px solid #96C4FB;
    height: inherit;
}

.markItUpHeader ul ul li {
    float: none;
    border-bottom: 1px solid #96C4FB;
}

.markItUpHeader ul ul .markItUpDropMenu {
    background: #F5F5F5 url(@public/images/markitup/submenu.png) no-repeat 100% 50%;
}

/* next rows of buttons */
.markItUpHeader ul ul ul {
    position: absolute;
    top: -1px;
    left: 150px;
}

.markItUpHeader ul ul ul li {
    float: none;
}

.markItUpHeader ul a {
    display: block;
    width: 16px;
    height: 16px;
    text-indent: -10000px;
    background-repeat: no-repeat;
    padding: 3px;
    margin: 0px;
}

.markItUpHeader ul ul a {
    display: block;
    padding-left: 0px;
    text-indent: 0;
    width: 120px;
    padding: 5px 5px 5px 25px;
    background-position: 2px 50%;
}

.markItUpHeader ul ul a:hover {
    color: #FFF;
    background-color: #96C4FB;
}

/*****************************************/
.html .markItUpEditor {
    background-image: url(@public/images/markitup/bg-editor-html.png);
}

.markdown .markItUpEditor {
    background-image: url(@public/images/markitup/bg-editor-markdown.png);
}

.textile .markItUpEditor {
    background-image: url(@public/images/markitup/bg-editor-textile.png);
}

.bbcode .markItUpEditor {
    background-image: url(@public/images/markitup/bg-editor-bbcode.png);
}

.wiki .markItUpEditor,
.dotclear .markItUpEditor {
    background-image: url(@public/images/markitup/bg-editor-wiki.png);
}

.markItUp .palette a {
    background-image: url(@public/images/markitup/colors.png);
}

.markItUp .palette ul {
    width: 81px;
    padding: 1px;
}

.markItUp .palette li {
    border: 1px solid white;
    width: 25px;
    height: 25px;
    overflow: hidden;
    padding: 0px;
    margin: 0px;
    float: left;
}

.markItUp .palette ul a {
    width: 25px;
    height: 25px;
}

.markItUp .palette ul a:hover {
    background-color: none;
}

.markItUp .palette .col1-1 a {
    background: #FCE94F;
}

.markItUp .palette .col1-2 a {
    background: #EDD400;
}

.markItUp .palette .col1-3 a {
    background: #C4A000;
}

.markItUp .palette .col2-1 a {
    background: #FCAF3E;
}

.markItUp .palette .col2-2 a {
    background: #F57900;
}

.markItUp .palette .col2-3 a {
    background: #CE5C00;
}

.markItUp .palette .col3-1 a {
    background: #E9B96E;
}

.markItUp .palette .col3-2 a {
    background: #C17D11;
}

.markItUp .palette .col3-3 a {
    background: #8F5902;
}

.markItUp .palette .col4-1 a {
    background: #8AE234;
}

.markItUp .palette .col4-2 a {
    background: #73D216;
}

.markItUp .palette .col4-3 a {
    background: #4E9A06;
}

.markItUp .palette .col5-1 a {
    background: #729FCF;
}

.markItUp .palette .col5-2 a {
    background: #3465A4;
}

.markItUp .palette .col5-3 a {
    background: #204A87;
}

.markItUp .palette .col6-1 a {
    background: #AD7FA8;
}

.markItUp .palette .col6-2 a {
    background: #75507B;
}

.markItUp .palette .col6-3 a {
    background: #5C3566;
}

.markItUp .palette .col7-1 a {
    background: #EF2929;
}

.markItUp .palette .col7-2 a {
    background: #CC0000;
}

.markItUp .palette .col7-3 a {
    background: #A40000;
}

.markItUp .palette .col8-1 a {
    background: #FFFFFF;
}

.markItUp .palette .col8-2 a {
    background: #D3D7CF;
}

.markItUp .palette .col8-3 a {
    background: #BABDB6;
}

.markItUp .palette .col9-1 a {
    background: #888A85;
}

.markItUp .palette .col9-2 a {
    background: #555753;
}

.markItUp .palette .col9-3 a {
    background: #000000;
}

/* -------------------------------------------------------------------
// markItUp!
// By Jay Salvat - http://markitup.jaysalvat.com/
// ------------------------------------------------------------------*/
.markItUp .markItUpButton1 a {
    background-image: url(@public/images/markitup/bold.png);
}

.markItUp .markItUpButton2 a {
    background-image: url(@public/images/markitup/italic.png);
}

.markItUp .markItUpButton3 a {
    background-image: url(@public/images/markitup/underline.png);
}

.markItUp .markItUpButton4 a {
    background-image: url(@public/images/markitup/picture.png);
}

.markItUp .markItUpButton5 a {
    background-image: url(@public/images/markitup/link.png);
}

.markItUp .markItUpButton6 a {
    background-image: url(@public/images/markitup/fonts.png);
}

.markItUp .markItUpButton7 a {
    background-image: url(@public/images/markitup/list-bullet.png);
}

.markItUp .markItUpButton9 a {
    background-image: url(@public/images/markitup/youtube.png);
}

.markItUp .markItUpButton8 a {
    background-image: url(@public/images/markitup/list-item.png);
}

.markItUp .markItUpButton11 a {
    background-image: url(@public/images/markitup/quotes.png);
}

.markItUp .clean a {
    background-image: url(@public/images/markitup/clean.png);
}

.markItUp .resize a {
    background-image: url(@public/images/markitup/resize.png);
}
