#mprofCont {
    width: 580px;
    margin: auto;
}

.mprofileError {
    color: #f00;
    font-size: 12;
    white-space: nowrap;
    text-align: left;
}

.mpTable {
    border: 0;
    width: 98%;
    border-spacing: 0;
    margin: auto;
}

.mpTable td {
    padding: 10px 0 10px 0;
    border-bottom: 1px solid #ccc;
    vertical-align: middle;
}

.lfor td {
    border: 0;
}

.mpTable tr {
}

.mpSectionHead {
    background: #ccf;
}

.mpSectionHead b {
    font-size: 120%;
    color: #666;
}

#profForm textarea {
    height: 80px;
}

.mprofSections {
    color: #555;
    font-weight: bold;
    padding: 5px;
}

.mpLangDropDown {
    margin-bottom: 3px;
}

.mpTable .markItUp {
    width: 420px;
}
