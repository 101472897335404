#acctTopWelcome {
    width: 600px;
    margin: 0 auto;
    overflow: hidden;
}

#acctTopWelcomeThumb {
    float: left;
    margin-right: 10px;
    padding-top: 4px;
}

#acctTopWelcomeThumbLink {
    text-align: center;
}

#acctTodoCnt {
    background: #CEF5AE;
    overflow: hidden;
    width: 380px;
    float: left;
    border-radius: 12px;
    -moz-border-radius: 12px;
    text-align: center;
    padding: 5px 0 10px 0;
    color: #008703;
    margin-left: 5px;
}

.acctBottomLinks {
    color: #999;
    padding: 3px 3px 0 3px;
    overflow: hidden;
    text-align: left;
    position: absolute;
    bottom: 0;
}

.acctBottomLinks a {
    color: #999;
    text-decoration: underline;
}

#tdTitle {
    font-size: 16px;
    font-weight: normal;
}

#tdText {
    font-size: 13px;
}

#tdLinks {
    background: #BAEE93;
    padding: 5px;
    overflow: hidden;
    width: 350px;
    margin: auto;
    margin-top: 3px;
}

#acctTodoCnt a.tdl {
    float: left;
    display: block;
    font-size: 12px;
    line-height: 13px;
    text-align: center;
    margin-left: 15px;
}

#as {
    padding: 0;
    margin: 15px auto;
    overflow: hidden;
    width: 615px;
}

.asLU {
    float: right;
    font-size: 12px;
    font-family: verdana;
    color: #2e63ad;
    background: #eee;
    border: 1px solid #ccc;
    padding: 3px 5px;
    border-radius: 8px;
    -moz-border-radius: 8px;
}

.asLU-explain {
    font-size: 10px;
    color: #999;
}

#asCur {
    float: left;
}

#asMsg {
    float: left;
    font-size: 13px;
    font-family: verdana;
    color: #999;
    padding: 5px;
    max-width: 400px;
    width: expression(document.body.clientWidth >   400 ?   "400px" :   "auto");
}

#asQ a {
    text-decoration: underline;
    float: right;
}

#asEditLink {
    float: right;
    line-height: 13px;
    padding: 5px;
    display: none;
}

#asEdit {
    float: left;
}

#asEditCode, #asEditMsg, #asEditLinks {
    float: left;
}

#asEditCode {
    margin-top: 2px;
}

#asEditLinks {
    padding-top: 3px;
    color: #999;
    color: #2e63ad;
}

#asEditLinks .underlink_inline {
    display: inline;
}

#asEditMsg {
    width: 300px;
    margin-right: 5px;
}

#asQ {
    float: left;
    margin-right: 5px;
    color: #666;
    font-size: 1.1em;
    padding: 5px;
    text-align: center;
}

#asFeed {
    font-size: 11px;
    color: #666;
}

#aQS {
    overflow: hidden;
    min-width: 580px;
    max-width: 620px;
    width: expression(document.body.clientWidth <   580 ?   "580px" :   "auto");
    width: expression(document.body.clientWidth >   620 ?   "620px" :   "auto");
    margin: auto;
    display: none;
}

#aQS .outlinedBox {
    padding-right: 0;
}

.aQSBody {
    border: 0;
}

.aQSField {
    margin-right: 7px;
    float: left;
}

.aQSField .search_field {
    margin-left: 5px;
    float: left;
}

.aQSField h3 {
    display: inline;
}

#aQSSubmit {
    float: right;
    display: block;
    margin-top: 51px;
    margin-right: 4px;
}

#aQSResults {
    background: #F0FBFF;
    border-radius: 2px;
    overflow: hidden;
    margin-top: 3px;
    float: left;
}

#aQSResults .ppThLink {
    width: 112px;
    padding: 0;
    display: block;
    float: left;
    overflow: hidden;
}

#aQSResults .photo_prof {
    margin: 0;
    width: 112px;
    height: auto;
}

#aQSResults .user_info {
    float: left;
    width: 112px;
    padding-bottom: 3px;
}

#aQS h1 {
    color: #338eee;
    display: block;
    float: left;
}

.aQSBody {
    background: #D8F5FF;
}

#aFeed {
    overflow: hidden;
    margin: 0 auto;
    float: left;
}

#aFeed .boxTitle a {
    color: #888;
}

#aFeedBody hr {
    display: none;
}

.vert#aViews {
    float: right;
    width: 240px;
}

#aBotCont {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
}

#aBotSpacer {
    width: 25px;
    height: 1px;
    float: right;
}

.wbp_wrap {
    padding: 3px 5px;
    width: 326px;
    overflow: hidden;
}

.wbp_title {
    font-weight: bold;
    color: #666666;
}

.wbp_post {
    margin-bottom: 5px;
}

.wbp_wrap HR {
    border: 0;
    height: 1px;
    background: #d9d9d9;
    background-image: linear-gradient(left, #eee, #d9d9d9, #eee);
    background-image: -webkit-linear-gradient(left, #eee, #d9d9d9, #eee);
    background-image: -moz-linear-gradient(left, #eee, #d9d9d9, #eee);
    background-image: -ms-linear-gradient(left, #eee, #d9d9d9, #eee);
    background-image: -o-linear-gradient(left, #eee, #d9d9d9, #eee);
}

/* account_activity.php */
.acctActUAString {
    padding: 0 50px 0 20px;
    font-style: italic;
}

#acctActLoginTable {
    margin: 10px;
    border: 1px solid #ddd;

}

#acctActLoginTable th {
    background: #eee;
}

#acctActLoginTable th,
#acctActLoginTable td {
    color: #666;
    padding: 3px;
}

.acctActInfo {
    width: 600px;
    margin: auto;
}

.acctActInfo ul {
    margin-left: 40px;
    line-height: 18px;
}

#acctActLoginTable tr.colored {
    background-color: #eee;
}