
.btn-fb {
    font-family: Helvetica, Arial, sans-serif;
    background-color: #4267b2;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 18px;
    height: 40px;
    vertical-align: middle;
    user-select: none;
    letter-spacing: .25px;
    padding: 0 8px;
    border-radius: 4px;
}

.btn-fb:hover {
    background-color: #2d4373;
}

.btn-fb span {
    line-height: 40px;
    margin: 0 16px;
}

.btn-fb i {
    line-height: 40px;
    font-size: 24px;
    float: left;
}

.adStyle {
    width: auto !important;
    height: auto !important;
    margin: 0 !important;
    text-align: center !important;
}

.blueBar {
    padding: 5px;
    display: block;
    color: #fff;
    text-decoration: none;
}

.blueBar h1, .blueBar h2 {
    font-family: "Helvetica Neue", Helvetica;
    text-rendering: optimizelegibility;
    display: block;
    float: left;
    clear: left;
    font-weight: bold;
    text-align: center;
}

.blueBar h1 {
    padding: 3px 8px 3px 8px;
    color: rgb(25, 181, 254);
    font-size: 32px;
    opacity: 0.8;
    margin: 5px 0 10px 0;
    letter-spacing: -1px;
    border-radius: 12px;
    background-color: #fff;
    box-shadow: 5px 5px 30px 4px rgba(0, 0, 0, 0.05);
}

.blueBar h2 {
    color: #888;
    font-size: 22px;
    border: 0;
}

.blueBar a, .blueBar a:visited {
    color: #fff;
    text-decoration: none;
}

.index-intro {
    clear: left;
    color: #666;
    font-size: 14px;
    font-family: Verdana;
}

.index-intro-top {
}

.index-intro-bottom {
    color: #999;
}

.blueBar a:hover {
    text-decoration: underline;
}

.blueBar .bbLink {
    font-size: 12px;
    font-weight: normal;
    color: #fff;
}

.index-bb-1 div {
    opacity: .60;
    font-size: 18px;
}

.index-bb-2 div {
    opacity: .70;
    font-size: 20px;
}

.index-bb-3 div {
    opacity: .80;
    font-size: 26px;
}

.index-bb-4 div {
    font-size: 32px;
}

.hiddenPic {
    display: none;
}

#indexMidBox {
    font-family: Tahoma, Helvetica, sans-serif, Arial;
    /*
    overflow: hidden;
    */
    width: 850px;
    margin: auto;
    margin-bottom: 25px;
}

#indexBotBox {
    overflow: hidden;
    width: 935px;
    margin: 0 auto;
    margin-top: 15px;
}

.index-su-member-total {
    clear: both;
    text-align: center;
    margin-top: 10px;
}

.langDataBox {
    float: left;
    width: 68px;
    text-align: center;
    margin: 5px 0 1px 0;
    overflow: hidden;
    white-space: nowrap;
}

.langDataBox a:hover {
    text-decoration: underline;
    color: #0865E6;
}

#suTitle {
    font-size: 16px;
    font-weight: bold;
    float: left;
}

#suTopIntro {
    margin-left: 55px;
    float: left;
}

.suSubT {
    font-size: 12px;
    line-height: 16px;
    float: left;
    margin: 5px 0 10px 0;
}

#fpSuForm {
    clear: both;
    width: 260px;
    margin: auto;
}

#fpSuForm label {
    font-weight: normal;
    color: #fff;
}

#fpSuForm input[type~="text"], #fpSuForm input[type~="password"] {
    border-color: #9999ff;
    width: 170px;
    font-family: verdana;
}

#fpSuForm input[type="submit"] {
    border-color: #eee;
}

#suCountry {
    width: 130px;
}

#fpSuForm td {
    padding-top: 7px;
}

#suBox {
    width: 300px;
    float: left;
    margin: 0 0 2px 41px;
    font-family: Verdana;
    /*
    border: 3px solid #60a5fd;
    */
    border-radius: 12px;
    -moz-border-radius: 12px;
    background: #93C2FD;
    padding: 15px;
    color: #fff;
    text-align: center;
}

#suBox .easy-autocomplete {
    color: #000000;
    text-align: left;
}

#suBox input, #suBox select {
    color: #000;
}

.in_tos, .in_tos A {
    color: white;
    font-size: 9px;
    text-shadow: none;
}

.in_tos A {
    font-weight: bold;
    text-decoration: underline;
}

.suLine {
    clear: both;
    margin: 3px 0;
    padding: 1px;
    display: block;
    overflow: auto;
}

.suLine :focus {
    background: #ffc;
}

.suErr {
    /*border: 1px solid #FF555B;*/
    border: 1px solid #eeeeee;
    border-radius: 4px;
    -moz-border-radius: 4px;
    padding: 5px 1px;
}

.suLab {
    width: 75px;
    float: left;
    line-height: 22px;
    text-align: right;
    padding-right: 3px;
    font-size: 12px;
    /*
    overflow: hidden;
    */
}

.suErrMsg {
    color: #bf2c33;
    font-size: 10px;
    margin: 0 0 10px 15px;
    text-align: left;
}

#indexMidLeft {
    float: left;
    /*
    overflow: hidden;
    */
    width: 470px;
}

.indexMapGraphic {
    margin: 20px auto;
    width: 450px;
}

.indexTitleBar {
    color: #666;
    font-size: 14px;
    padding: 5px 0;
    border-bottom: 1px solid #ccc;
    font-family: Tahoma, Helvetica, sans-serif, Arial;
    /*
    overflow: hidden;
    */
    text-align: left;
}

.indexTitleBar .bold, .indexTitleBar a.bold {
    font-weight: bold;
    color: #6088C8;
}

.indexUserSet {
    overflow: hidden;
}

.indexUserSet .photo_prof {
    height: 155px;
    width: 100px;
}

.indexUserSet .photo_prof .ppThLink img {
    margin: 0;
}

.indexUserSet .photo_prof .ppThLink {
    overflow: hidden;
    display: block;
    padding: 0;
    margin: 5px 0 5px 0;
}

.indexUserBars {
    width: 850px;
    margin: auto;
    overflow: hidden;
}

#downtimeAnnounce {
    margin: 0 auto 10px auto;
    text-align: center;
    border-bottom: 1px solid #aaa;
    padding: 8px;
    background: #eee;
}

#adblockAlert {
    margin: 0 auto 15px auto;
    text-align: center;
    border-bottom: 1px solid #ffffff;
    padding: 12px;
    background: #E73B73;
    color: #ffffff;
}
