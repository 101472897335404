/* Address Book Importer (for inviter) */
#gcCLTable {
    width: 550px;
    font-size: 12px;
    border-right: 1px solid #999;
}

#gcCLTable td {
    padding: 3px;
    border-left: 1px solid #999;
    border-bottom: 1px solid #999;
}

#gcCLTable th {
    padding: 3px;
    background: #bbd;
    text-align: left;
}

#gcForm input[type~="text"], #gcForm input[type~="password"] {
    width: 200px;
    margin-left: 10px;
}

#gcForm input[name~="captcha"], #gcManual input[name~="captcha"] {
    width: 69px;
    height: 30px;
    font-size: 18px;
    color: #999;
}

#gcForm {
    background: #FFF2DD;
    border: 5px solid #FFD492;
    margin: 10px 5px 10px 0;
    padding: 10px;
}

#gcForm label, #gcManual form label {
    font-size: 12px;
}

#gcForm h2 {
    padding-left: 0;
    border: 0;
}

#gcForm p, #gcManual p {
    line-height: 18px;
    margin-bottom: 5px;
}

#gcManual {
    float: left;
    overflow: hidden;
    padding: 10px 5px 10px 0;
    line-height: 18px;
}

#gcManual form {
    background: #AEFF95;
    padding: 10px;
    width: 260px;
    border: 5px solid #68FF3A;
}

#gcError {
    color: #ff0000;
    font-weight: bold;
}

#gcAuto {
    float: left;
    overflow: hidden;
}

#gcInstr {
    width: 250px;
    float: left;
    overflow: hidden;
}

#gcInstr p {
    margin-bottom: 15px;
}
