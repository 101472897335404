.settingsFormTable {
    width: 500px;
    paddding: 10px;
}

.settingsFormTable .sfLeft {
    min-width: 150px;
}

.settingsFormTable td {
    vertical-align: top;
    padding: 5px;
}

.settingsFormTable .sfLeft label {
    display: block;
    text-align: right;
    margin-right: 10px;
    line-height: 18px;
}

.settingSections {
    color: #555;
    font-weight: bold;
    padding: 5px;
}
