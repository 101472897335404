.suStepBox {
    overflow: hidden;
    border-radius: 25px;
}

.suStep {
    padding: 10px 15px;
    float: left;
    width: 170px;
    line-height: 20px;
    background: #B0E5FF;
    text-align: center;
    font-size: 12px;
}

.suStep a {
    color: #333;
    text-decoration: underline;
}

.suStepHL, .suStepHL a, .suStepHL a:visited {
    background: #008ACC;
    color: #fff;
    text-decoration: none;
}

#gsMainBox {
    width: 602px;
    margin: auto;
}

#gsStepBody {
    background: #eee;
    margin-top: 10px;
    padding: 15px;
    overflow: hidden;

    border-radius: 12px;
    -moz-border-radius: 12px;
}

#gsStepBody h1 {
    margin: 10px;
    color: #333;
}

#gsStepBody p {
    color: #666;
    margin: 10px;
    width: 400px;
}

#gsStepBody form {
    margin: 10px;
    color: #333;
    font-size: 12px;
}

.suSkip {
    text-align: right;
}

#gsFirstTime {
    margin-bottom: 10px;
    background: #EACDFF;
    border: 1px solid #A834FF;
    padding: 10px;
    font-size: 12px;
}

.gsPhImg {
    overflow: hidden;
    margin-left: 10px;
    border: 1px solid #99c;
    float: left;
    height: 120px;
    width: 120px;
    text-align: center;
    color: #eee;
}

.gsPhClickBar {
    margin-top: 100px;
    text-align: center;
    background: #99d;
}

#gsPhCont {
    position: relative;
}

#phFile {
    clear: left;
}

.gsfLine {
    overflow: hidden;

    margin-top: 10px;
}

.gsfLabel {
    float: left;
    width: 100px;
}

.gsfField {
    float: left;
}

.gsInviteErr {
    background: #FFBBBD;
    border: 1px solid #fff;
    width: 400px;
    padding: 10px;
    margin: 5px 0;
}

#ic_prog {
    width: 25px;
    height: 25px;
}

#gsInvite {
    width: 400px;
    margin: 0 auto;
}

#gsCLTable {
    background: #dde;
    font-size: 18px;
    font-family: Verdana;
    width: 100%;
}

#gsCLTable td, #gsCLTable th {
    background: #eef;
    padding: 8px 5px 8px 5px;
    border-bottom: 1px solid #fff;
    font-size: 16px;
    font-family: Verdana;
}

#gsCLTable th {
    background: #ccc;
}

#gsInvForm INPUT[type=text], #gsInvForm INPUT[type=password] {
    width: 200px;
}

.gsInvIcon {
    width: 64px;
    height: 80px;
    float: left;
    margin-right: 50px;
    padding-top: 70px;
    background-position: center;
    background-size: 64px 64px;
    text-align: center;
    color: #999;
    font-size: 14px;
    font-family: "Lucida Grande", "Lucida Sans Unicode", Verdana, Arial, Helvetica, sans-serif;
}

#gsInvIconYahoo {
    background: url(@public/images/invite-icons/yahoo2.png) no-repeat;
}

#gsInvIconOutlook {
    background: url(@public/images/invite-icons/outlook2.png) no-repeat;
}

#gsInvIconGmail {
    background: url(@public/images/invite-icons/gmail2.png) no-repeat;
}


#gsInvSourceCnt {
    width: 300px;
    height: 32px;
    overflow: hidden;
    margin: auto;
}

#gsInvBox {
    text-align: center;
    border: 5px solid #2289D2;
    background: #fff;
    padding: 10px;
    font: 16px Arial;
    margin: 20px auto;
    border-radius: 12px;
    -moz-border-radius: 12px;
}

#gsInvOuterCnt {
    width: 400px;
    margin: auto;
}

#gsInvOuterCnt h1 {
    margin-left: 0;
}
