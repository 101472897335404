.wall {
    padding: 0px;
    background: #fff;
    width: 550px;
    margin: 10px auto;
}

.wall_form {
    border: 0;
    padding: 5px 0 5px 15px;
    margin-bottom: 5px;
    background: #D1F2FA;
    border-bottom: 3px solid #89CBDB;
}

.wall_posts {
    margin-top: 10px;
}

.wall_thumb {
    float: left;
    margin: 5px 0 0 10px;
    width: 50px;
    text-align: right;
    padding: 2px;
    border: 1px solid #ccc;
}

.wall_post_msg {
    font-family: verdana;
    overflow: hidden;
    line-height: 16px;
    color: #333;
    word-wrap: break-word;
    margin: 10px 0;
}

.wall_post .wp-left-pane {
    float: left;
    width: 60px;
}

.wall_post .wp-mid-pane {
    float: left;
    margin-left: 20px;
    width: 400px;
}

.wp-signature {
    font-family: "Lucida Grande";
    font-size: 10px;
    color: #999;
}

.wall_post .wp-signature a {
    color: #999;
    text-decoration: underline;
}

.wall_post {
    overflow: hidden;
    margin-bottom: 20px;
    clear: left;
    background: #f6f6f6;
    padding: 5px 0;
}

.wall_post.wp-new {
    background: #d7ffcc;
}

.wall_post.wp-owner-post {
    background: #eef6fd;
}

.wall_post_data, .wall_post_data_owner, .wall_post_data_new {
    overflow: hidden;
    float: left;
    font-family: Tahoma, Helvetica, sans-serif, Arial;
    font-size: 12px;
}

.wp-user-link {
    font-weight: bold;
    margin-bottom: 4px;
    color: #999;
}

.wall_post_data_new {
    background: #FFFFCB;
}

.wall_post_data_owner .wall_post_msg {
    border: 2px solid #34B350;
}

.wp_time {
    margin-top: 3px;
    margin-left: 5px;
    color: #777;
    font-size: 10px;
}

.wall_post_links, .wall_post_links a {
    clear: left;
    color: #888;
    font-size: 12px;
    color: #718dc8;
    margin: 0 0 10px 0;
}

.wall_post_links .tzLink {
    font-size: 10px;
}

.wall_post_links a:hover {
    color: #000;
}

.inlineReplyBox {
    margin-top: 5px;
    line-height: 24px;
}

.wpHead {
    padding: 5px 2px;
    text-align: left;
    padding-left: 10px;
}

.wallPages {
    clear: both;
}

.pinkBox {
    background: #cbe5fd;
    margin: 5px 0;
    padding: 10px;
}

.pinkBox .boxText {
    padding: 0;
    margin: 0;
}

#profBody .wall {
    margin-top: 10px;
    padding-top: 10px;
}

.wall-comment-form label {
    font-size: 13px;
    font-family: verdana;
    font-weight: bold;
    color: #457FDD;
}

.wall-comment-form label i {
    color: #666;
}

.wall-comment-form textarea {
    margin-top: 10px;
    width: 490px;
    height: 50px;
}

.wall_post_location {
    font-weight: normal;
    font-size: 11px;
    display: inline;
    color: #666;
    margin-left: 10px;
}
