.MALE {
    color: #33f;
}

.FEMALE {
    color: #fdd;
}

#profRightBar {
    width: 360px;
    overflow: none;
}

.profile .boxHead#topBH {
    padding: 0;
    width: 925px;
    overflow: hidden;
    height: 27px;
    font-size: 14px;
    background: #69A2EF 0 100% repeat-x;
    color: #fff;
    font-weight: bold;
    text-decoration: none;
}

#profBody {
    overflow: hidden;
}

.profile .topBHUsername {
    float: left;
    padding: 5px;
    min-width: 170px;
}

.myProfNotice {
    margin: 5px auto;
    width: 550px;
    padding: 5px 5px 5px 35px;
    font-size: 12px;
    font-family: Tahoma, Helvetica, sans-serif, Arial;
    overflow: hidden;
    color: #333;
    background: #BCFFA0;
    border: 1px solid #77E04D;
    border-radius: 12px;
    -moz-border-radius: 12px;
}

#profBodyTop {
    width: 550px;
    margin: auto;
    overflow: hidden;
}

#prof-action-links {
    width: 550px;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 0;
    color: #000;
    border: 1px solid #ddd;
    border-left: 0;
    border-right: 0;
    text-align: center;
    padding: 5px 0 5px 0;
    border: 1px solid #ddd;
    background: #f3f3f3;
    border-radius: 8px;
    -moz-border-radius: 8px;
}

#action_links.alSmall {
    width: 300px;
}

#prof-action-links a, #prof-action-links a:visited {
    color: #295da6;
    font-size: 14px;
    padding: 0 5px;
}

#prof-action-links a i {
    color: #666;
}

#prof-action-links a:hover, #prof-action-links a:hover i {
    color: #999;
}

.profileBox {
    margin: 0 0 0 15px;
    float: left;
    font-size: 12px;
    color: #333;
    width: 320px;
    overflow: hidden;
}

.profileBox p {
    margin: 5px 0;
    font-size: 12px;
}

.profileBox p img {
    margin: 0 3px 0 5px;
}

.profDataBox {
    word-wrap: break-word;
    padding: 0;
    margin: 0;
    color: #000;
}

.profDataBox img {
    max-width: 500px;
    max-height: 500px;
}

.profDataBox h2 {
    padding: 4px 0 0 0;
    margin: 7px auto;
    margin-bottom: 0;
    width: 550px;
    border: 0;
    font-family: Verdana;
    font-size: 13px;
    color: #457FDD;
    font-weight: bold;
    /*border-bottom: 1px solid #ccc;*/
}

.profDataBox h2 i {
    color: #666;
}

.profDataBoxText {
    width: 550px;
    margin: 10px auto;
    margin-top: 2px;
    padding-left: 44px;
    font-family: Verdana, sans-serif;
    font-size: 13px;
    line-height: 18px;
    color: #333;
    word-wrap: break-word;
    overflow: hidden;
}

.profDataTopField {
    overflow: hidden;
    clear: left;
    padding-bottom: 5px;
    border-bottom: 1px solid #eef;
    margin-bottom: 5px;
}

.profDataTopField h3 {
    float: left;
    width: 80px;
    word-wrap: break-word;
}

.profDataTopData {
    padding-top: 3px;
    float: left;
    width: 255px;
    overflow: hidden;
    font-size: 12px;
    word-wrap: break-word;
}

.profLang {
    white-space: nowrap;
    display: block;
    width: 125px;
    float: left;
    height: 18px;
    overflow: hidden;
}

.prLangName {
    float: left;
}

.proflLevel {
    display: block;
    float: left;
    margin-left: 5px;
    margin-top: 3px;
}

.profDataTop {
    width: 372px;
}

#profPhotos {
    width: 200px;
    float: left;
}

#profPhotos a.profPhotoLink {
    border: 1px solid #ccc;
    margin: 2px;
    background: #fff;
    display: -moz-inline-box;
    display: inline-block;
}

#profPhotos a {
    color: #09924C;
    line-height: 18px;
    margin: 5px;
}

.addMF {
    float: left;
    width: 180px;
    text-align: center;
    margin-top: 0;
}

.addMF:hover {
    text-decoration: underline;
}

.profUplLink {
    overflow: hidden;
    width: 180px;
    height: 180px;
    float: left;
    position: relative;
}

.profUplTxt {
    background: #8E8ECD;
    color: #fff;
    height: 20px;
    position: absolute;
    top: 140px;
    width: 180px;
    text-align: center;
}

#profPhotos a.profPhotoLink:hover {
    border: 1px solid #0CCE6B;
}

#profPhotos a.mainPhoto:hover {
    border: 0;
}

#profPhotos img {
    margin: 3px;
}

.jumpTo {
    float: left;
    background: #8EB7EF;
    height: 27px;
}

.jumpTo a {
    color: #fff;
    text-decoration: none;
    display: block;
    float: left;
    margin-left: 1px;
    font-weight: normal;
    font-size: 12px;
    background: #8EB7EF;
    padding: 6px 10px 0 10px;
}

.jumpTo a:visited, a.editLink {
    color: #fff;
}

.jumpTo a:hover {
    text-decoration: underline;
}

.profModuleBox {
    clear: both;
    overflow: hidden;
    float: right;
    margin: 0 auto;
    padding-right: 5px;
    margin-bottom: 15px;
    width: 355px;
}

.profModules {
    overflow: hidden;
}

.profModules .boxHead {
    background: #fff;
    padding: 0;
    margin: 0;
    padding-bottom: 2px;
    margin-top: 10px;
}

.profModules .boxHead h1, .profModules .boxHead h1 a {
    background: #fff;
    padding: 0;
    margin: 0;
    text-decoration: none;
    color: #457FDD;
    font-size: 16px;
    font-weight: normal;
}

.profModuleBox .dataCont {
    overflow: hidden;
    width: 340px;
}

* html .profModuleBox .pmbCont {
    width: 100%;
}

.profModuleBox h1.boxHead {
    background: #E34E8F;
}

.profModuleBox .boxHead {
    width: auto;
}

.profBotStat {
    padding: 5px;
    padding-left: 50px;
    color: #666;
    width: 400px;
}

.editLink {
    text-decoration: underline;
    color: #fff;
}

.profAlbBoxUnit {
    float: left;
    text-align: center;
    padding: 3px;
    color: #999;
    width: 100px;
    overflow: hidden;
}

.profModuleBox .profAlbBoxUnit {
    margin: 0 3px;
}

.profAlbRow {
    overflow: hidden;
}

.profAlbBoxUnit a {
    text-decoration: underline;
    color: #222;
}

div.prEdLnk {
    float: right;
    font-size: 11px;
    font-weight: normal;
    color: #74A3D5;
}

.prEdLnk a {
    font-weight: normal;
    color: #74A3D5;
}

.prEdLnk a:hover {
    color: #3381D5;
    text-decoration: underline;
}

.lfor {
    font-family: verdana;
    font-size: 11px;
    color: #555;
}

.profRightMap {
    width: 340px;
    height: 150px;
    float: left;
    margin-top: 0;
    border-radius: 12px;
    -moz-border-radius: 12px;
    /* fix for border radius bug with -webkit-transform: translate3d */
    -webkit-mask-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC");
}

.profRightMap.big {
    height: 340px;
}

.mapWrapper {
    text-align: center;
}

#profRightMapTxt {
    text-align: center;
    font-family: Tahoma, Helvetica, sans-serif, Arial;
    font-size: 12px;
    line-height: 18px;
}

#profWildfire {
    width: 301px;
    margin: 0 auto;
    height: 151px;
    overflow: hidden;
    margin-bottom: 2px;
}

/**
 * STATUS MESSAGE
 *
 * Elements with 'as' prefix are also used on the account page
 * and depend on JS
 */
#asEditLink {
    float: right;
    line-height: 13px;
    padding: 0 5px 5px 5px;
    display: none;
}

#asEdit {
    float: left;
}

#asEditCode, #asEditMsg, #asEditLinks {
    float: left;
}

#asEditCode {
    margin-top: 2px;
}

#asEditLinks {
    padding-top: 3px;
    color: #999;
}

#asEditLinks .underlink_inline {
    display: inline;
}

#asEditMsg {
    width: 275px;
    margin-left: 5px;
    margin-right: 5px;
}

#asMsg, #prStatMsg {
    color: #00C300;
    overflow: hidden;
    margin-bottom: 15px;
}

.prStatUpdated {
    color: #999;
    font-size: 10px;
}

#prStatMsg i {
    color: #666;
}

#prStatMsgIcon {
    float: left;
    margin: 1px 4px 0 0;
}

#prStatMsg h2 {
    color: #999;
    float: left;
    border: 0;
    padding: 0;
    margin-right: 4px;
    font-weight: normal;
}

#prStatMsgBody {
    float: left;
    width: 300px;
}

#prStatQ {
    color: #666;
    font-size: 1.1em;
    font-style: italic;
}

#modpage-overlay {
    background-color: #000;
    cursor: wait;
}

.rViewed .photoProf {
    padding: 0 2px 5px 0;
}

.hiddenPart {
    display: none;
}

#profPhotos a.mainPhoto {
    border: 0;
}

#profPhotos img.mpImgBg {
    margin: 0;
    position: absolute;
    width: 100%;
    height: 50px;
    left: 0;
    bottom: 0;
}

.mpImgLinks {
    position: absolute;
    bottom: 0;
    height: 25px;
    z-index: 100;
    opacity: .5;
    filter: alpha(opacity=5);
    padding-left: 11px;
    width: 169px;
}

.mpImgWrap:hover .mpImgLinks,
#profPhotos .mpImgWrap:hover img.mpImgBg,
.mpImgWrap.hover .mpImgLinks,
#profPhotos .mpImgWrap.hover img.mpImgBg {
    opacity: 1;
    filter: alpha(opacity=1);
}

.mpImgLinks, #profPhotos .mpImgLinks a {
    color: white;
    font-weight: bold;
}

.mpImgWrap {
    position: relative;
    margin: 5px 3px;
    width: 180px;
}

#profPhotos .mpImgWrap a.profPhotoLink {
    border: 0;
}

#profPhotos .mpImgWrap img {
    margin: 0;
}

#profPhotos .mpImgWrap a.profPhotoLink {
    margin: 0;
}

.profLimited {
    text-align: center;
    margin: 50px auto;
    font-size: 16px;
}

.profOnlineStatus {
    margin-bottom: 10px;
}

.profLocation {
    border: 0;
    margin-bottom: 5px;
    padding-bottom: 0;
}

.mprofRow td {
    vertical-align: top;
}
