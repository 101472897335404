#filterBox {
    border: 1px solid #FFE25A;
    background: #FFF4C0;
    width: 100%;
    padding: 0px 3px 5px 3px;
    text-align: center;
    margin: 10px 0;
    height: 65px;
    border-radius: 12px;
    -moz-border-radius: 12px;

}

.online_prof {
    width: 120px;
    float: left;
    margin: 5px;
    padding: 5px;
    text-align: center;
    font-size: 12px;
    height: 150px;
    width: 120px;
}

#filterBox form {
    text-align: left;
    overflow: hidden;
}

.viewLinks a {
    text-decoration: underline;
}

.viewLinks {
    color: #999;
}

.filterLinks {
    overflow: hidden;
    float: left;

    height: 25px;
}

.filterLinks a {
    display: block;
    float: left;
    margin: 3px;
    font-size: 12px;
    padding: 3px;
    text-decoration: underline;
}

.filterFormField {
    text-align: left;
    padding: 5px;
    float: left;
}

.olUserDetails {
    clear: both;
    color: #888;
    font-size: 11px;
    line-height: 16px;
}

.olAd {
    overflow: hidden;

    border: 1px solid;
    border-radius: 12px;
    -moz-border-radius: 12px;
    float: left;
    width: 336px;
    padding-left: 20px;
    width: 750px;
    margin: 10px 50px;
    border-color: #DFF5FF;
    background-color: #F1FAFF;

}

.onlineCont {
    width: 920px;
    margin: auto;
    overflow: none;

}

