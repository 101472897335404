
.views {
    padding: 0px;
    background: #fff;
    width: 660px;
    margin: 0 auto;
    overflow: hidden;
}

.views .time {
    margin-top: 3px;
    margin-left: 15px;
    color: #999;
    font-size: 10px;
}

.viewBox {
    float: left;
    color: #666;
    text-align: center;
    padding: 5px;
    background: #E8EFFF;
    margin: 5px 3px;
    width: 130px;
    height: 176px;
    text-align: center;
}

.view_list .photo_prof {
    float: none;
    margin: 0 auto;
}

.viewBox.female {
    background: #F4ECF5;
}

.viewBox .thumb {
    border: 1px solid #666;
    padding: 1px;
}

.vBottomTxt {
    clear: both;
    text-align: center;
}

.viewList .botRectAd {
    clear: both;
    overflow: hidden;
}
