
a.imgbutton {
    display: block;
    border: 1px solid #999;
    padding: 3px 5px 3px 5px;
    margin: 3px;
    text-align: center;
    color: #0067cd;
    font: normal bold 12px/12px Tahoma, Helvetica, sans-serif, Arial;
    border-radius: 6px;

}

a.imgbutton {
    background: #fff;
}

a.imgbutton img {
    margin: 0 5px 0 0;
    vertical-align: text-bottom;
}

a.imgbutton:hover {
    border: 1px solid #4884c0;
}

a.imgbutton:hover {
    border: 1px solid #666;
    background: #dde;
}

