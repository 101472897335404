.trVarHL {
    color: #E88A22;
}

.trHtmlHL {
    color: #1B9872;
}

.transNav a {
    text-decoration: underline;
    color: #888;
}

.transNav {
    color: #888;
}

.termTable {
    margin-left: 5px;
    border-spacing: 0;
}

.termTable td {
    padding: 5px;
    border-bottom: 1px dashed #999;
}

.termTable td a {
    text-decoration: underline;
    color: #555;
}

.tTabTerm {
    width: 340px;
    padding-right: 8px;
}

.tTabStat {
    color: #999;
    width: 200px;
}

.termBox {
    padding: 8px;
    background: #ffc;
    border: 1px solid #c9c;
}

.trans h1 {
    color: #558;
    font-size: 16px;
    border: 0;
    margin: 10px 0 4px 0;
}

.trLangStat {
    width: 150px;
    float: left;
    padding-right: 15px;
    margin: 0;
    border: 1px solid transparent;
    border-bottom: 1px solid #ccc;
    display: block;
    padding-left: 30px;
}

.trLangStat:hover {
    background-color: #ccf;
    border: 1px solid #ccc;
}

.trLSinfo {
    font-size: 12px;
    color: #999;
}

.trLShead {
    line-height: 19px;
    font-size: 16px;
    color: #333;
}

#trInstruct ol {
    list-style-type: decimal;
    padding: 0 10px 10px 25px;
}

#trInstruct ol li {
    margin: 4px;
}

.trExample {
    font-size: 11px;
    color: #777;
    margin-top: 5px;
    margin-left: 10px;
}

.trVoteCol {
    overflow: hidden;
}

.trVoteLink {
    width: 16px;
    height: 16px;
    float: left;
    margin-right: 3px;
    display: none;
}

.trThumbUp {
    background: url(@public/images/icons/thumb-up-16.png) no-repeat;
}

.trThumbDown {
    background: url(@public/images/icons/thumb-down-16.png) no-repeat;
}

.trvCnt {
    float: left;
    height: 16px;
    margin: 2px;
}

.trCompleteBG {
    margin: 3px 0;
    background: #eee;
    height: 15px;
    width: 100px;
}

.trCompleteBar {
    background: #6c6;
    height: 15px;
}
