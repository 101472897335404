.liveStatMsg {
    float: left;
    color: #555;
    width: 400px;
    font-size: 13px;
    margin-top: 10px;
    overflow: hidden;
}
.liveStatRow {
    overflow: hidden;
    margin: 10px;
    border-bottom: 1px solid #ccc;
}
.liveStatMsgTime {
    font-size: 11px; 
    color: #999;
}

