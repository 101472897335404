/* alerts/warnings */
#alert_bar {
    padding: 5px;
    margin: 0px 8px 3px 3px;
    overflow: auto;

}

.msg, .msg_warn, .msg_error, .msg_info, .msg_success, .msg_check {
    margin: auto;
    max-width: 550px;
    width: expression(document.body.clientWidth > 550 ? "550px": "auto" );
    padding: 5px 5px 5px 35px;
    background: #ffebe8;
    font-size: 12px;
    font-family: verdana, sans-serif;
    overflow: auto;
    margin-bottom: 15px;

    color: #333;
}

.msg ul, .msg_warn ul, .msg_error ul, .msg_info ul, .msg_success ul, .msg_check ul {
    margin: 0 0 0 -20px;
    padding: 10px 0 5px 50px;
    list-style-type: none;
    min-height: 25px;
}

* html .msg ul, * html .msg_warn ul, * html .msg_error ul, * html .msg_info ul,
* html .msg_success ul, msg_check ul{
    height: 25px;
}

.msg_tiny_pic {
    float: left;
    margin-right: 25px;
    vertical-align: middle;
}

.msg_error {
    border: 1px solid #D84A63;
    background: #F7E4E3;
}

.msg_error ul {
    background: url(@public/images/icon-error.png) 3px 50% no-repeat;
}

.msg_success, .msg_check {
    background: #BCFFA0;
    border: 1px solid #77E04D;

}

.msg_success ul, .msg_check ul {
    background: url(@public/images/icon-check.png) 0px 50% no-repeat;
}

.msg_warn {
    background: #F7EFE3;
    border: 1px solid #C84300;
}

.msg_warn ul {
    background: url(@public/images/icon-error.png) 0px 50% no-repeat;
}

.msg_info {
    background: #e1f3ff url(@public/images/icon-info.png) 15px 50% no-repeat;
    border: 1px solid #dde;
}

.msg_info h2 {
    border: 0;
    margin-bottom: 10px;
}

.msg a, .msg_warn a, .msg_error a, .msg_info a, .msg_success a , .msg_check a{
}
