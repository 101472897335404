.friendReqBox {
    float: left;
    margin: 10px;
    width: 155px;
    line-height: 18px;
    text-align: center;
    background: #eee;
    padding: 10px;
}

.friendReqBox .photo_prof, .friendBox .photo_prof {
    float: none;
    margin: 0;
}

.friendReqMsgLink {
    background: #FEEAB7;
    border: 1px solid #999;
}

.friendReqMsg {
    background: #FEEAB7;
    border: 1px solid #999;
}

.friendBox {
    float: left;
    margin: 5px 10px;
    line-height: 18px;
    text-align: center;
}

.friendBox .photo_prof {
    height: auto;
}

.friends {
    max-width: 820px;
}

.friendsPage {
    clear: both;
}