.cmtBox {
    width: 480px;
    margin: auto;
    overflow: hidden;
    word-wrap: break-word;
    clear: both;
}

.cmt {
    line-height: 16px;
    overflow: hidden;
    margin-top: 10px;
}

.cmtThumb {
    display: block;
    float: left;
    overflow: hidden;
    margin: 5px;
}

.cmtContainer {
    float: left;
    width: 350px;
    margin-bottom: 10px;
}

.cmtHead {
    background: #f9f9f9;
    padding: 5px;
    border-top: 1px solid #69f;
    border-bottom: 1px solid #69f;
}

.cmtAgeCity {
    color: #666;
    margin-left: 10px;
}

.cmtAgeCity img {
    margin-right: 5px;
    margin-left: 5px;
}

.cmtPosted {
    color: #333;
    font-size: 9px;
}

.cmtBody {
    line-height: 14px;
    margin-top: 3px;
    color: #555;
}

.cmtMsgLink {
    font-size: 9px;
}

.cmtForm {
    width: 400px;
    margin: 5px auto;
    clear: both;
}

.cmtFormBox {
    width: 400px;
    height: 100px;
    margin: 3px 0 10px 0;
}

.cmtHeadNew {
    background: #ffffcb;
}

.newCmtCntBld, .newCmtCnt {
    color: #FF4F56;
}

.newCmtCntBld {
    font-weight: bold;
}