.bmProfBox {
    float: left;
    text-align: center;
    margin-bottom: 10px;
    height: 160px;
    max-width: 120px;
}

.bookmarksRange {
    clear: both;
}
