.msgTable {
    border: 0;
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    margin: 0;
    clear: both;
}

.msgTable td {
    padding: 3px;
    vertical-align: middle;
    line-height: 18px;
    border-top: 1px solid #ccc;
}

.msgTable .msgTH {
    background: #eee;
}

.msgTable .msgSubject {
    font-weight: bold;
}

.msgTable .sentOn {
    font-size: 10px;
    color: #999;
}

.msgTable .fromBox {
    width: 130px;
}

.msgTable .fromBox.modmsg a {
    color: red;
}

.msgTable .fromThumb {
    overflow: hidden;
    width: 68px;
}

.msgTable .fromThumb a {
    padding: 2px;
    overflow: hidden;
    display: block;
    float: right;
    margin: 0;
    border: 1px solid transparent;
}

.msgTable .fromThumb a:hover {
    border: 1px solid #529BFF;
}

.msgTable .subjBox {
    width: 273px;
    word-wrap: break-word;
}

.msgTable .subjBox.modmsg a {
    color: red;
}

.msgTable .chkBox {
    width: 15px;
}

.msgTable .repliedBox {
    width: 15px;
}

.msgTable .newMsgRow {
    background: #ffdede;
}

.msgTable .replyDeleteBox {
    width: 20px;
    line-height: 24px;
}

.msgTable .replyDeleteBox a {
    display: block;
}

.mrSelected {
    background: #FFFFc0;
}

.msgRedX {
    background: url(@public/images/icons/x-red-16.png) no-repeat;
}

.msgSpamIcon {
    background: url(@public/images/icons/alert-16.png) no-repeat;
}

.delMsgLink, .msgSpamLink {
    width: 16px;
    height: 16px;
    display: block;
    -khtml-opacity: .15;
    -moz-opacity: .15;
    -ms-filter: "alpha(opacity=15)";
    filter: alpha(opacity=15);
    opacity: .15;
}

.delMsgLink:hover, .msgSpamLink:hover {
    -khtml-opacity: 1.00;
    -moz-opacity: 1.00;
    -ms-filter: "alpha(opacity=100)";
    filter: alpha(opacity=100);
    opacity: 1.0;
}

.msgSpamLink {
    margin-top: 8px;
}

.mBoxSelectPages {
    border-top: 1px solid #999;
    background: #eee;
    clear: both;
    overflow: hidden;
    padding: 5px 10px;
}

.spLink {
    float: left;
    margin-right: 20px;
    margin-top: 5px;
}

.spLink a {
    font-weight: bold;
    font-size: 11px;
}

.mBoxNav {
    overflow: visible;
    margin: auto;
}

.mBoxNav a {
    display: block;
    float: right;
    margin: 0 0px 0 5px;
    padding: 3px;
    font-weight: bold;
    font-size: 12px;
    border: 1px solid #999;
    border-bottom: 1px solid #999;
    top: 1px;
    position: relative;
    background: #fcfcfc;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    -moz-border-radius-topleft: 6px;
    -moz-border-radius-topright: 6px;
}

.mBoxNav a:hover {
    background: #fff;
    color: #333;
}

.mBoxNav a.cur, .mBoxNav a.cur:hover {
    background: #eee;
    border-bottom: 1px solid #eee;
}

.mBoxNav img {
    margin-right: 5px;
}

.msgHistMsg {
    color: #999;
    margin-top: 5px;
}

.mhMsgCont {
    overflow: hidden;
    margin: 3px 3px 8px 3px;
}

.mhMsg {
    float: left;
    margin-left: 10px;
    width: 400px;
}

.mhBody {
    color: #444;
    padding: 10px;
    padding-top: 0;
    background: #EEF3FF;
}

.mhBodyMe {
    background: #fff;
}

.mhBody h2 {
    border: 0;
    font-size: 13px;
    color: #666;
    padding: 5px 0;
    margin: 0;
}

.mhSent {
    overflow: hidden;
    margin: 5px;
    text-align: left;
    color: #777;
}

.mhMe {
    color: #50A2B8;
    font-weight: bold;
}

.mhYou {
    color: #2F6473;
    font-weight: bold;
}

.mhBody img {
    max-width: 300px;
    max-height: 500px;
}

#cbMH {
    overflow: hidden;
    width: 525px;
}

/* mcompose.php */
.composeTable {
    display: block;
    overflow: hidden;
    width: 490px;
}

.composeTable td {
    vertical-align: top;
    padding: 5px;
}

.ctLeftCol {
    text-align: right;
    padding-top: 5px;
    font-size: 14px;
}

.origMsg {
    padding: 5px;
    line-height: 14px;
    background: #eee;
    overflow: hidden;
}

.origMsg h2 {
    margin-bottom: 5px;
    border: 0;
    font-size: 14px;
    color: #333;
    padding: 0;
}

.omsgLink {
    padding: 5px;
    background: #eee;
    font-size: 12px;
    display: block;
}

#omsgLink img {
    margin-right: 5px;
}

.omsgBody {
    background: #fff;
    width: 430px;
    float: left;
    padding: 5px;
    line-height: 16px;
    color: #444;
    word-wrap: break-word;
}

.composeForm {
    padding: 15px;
}

.cfLeft {
    text-align: right;
}

.cfRight {
    text-align: left;
}

.composeForm input, textarea {
    border: 1px solid #BBD3FF;
    line-height: 14px;
    font-size: 12px;
}

#msgBodyCont {
    width: 625px;
    margin: 0 auto;
    overflow: hidden;
}

#msgReplyForm {
    float: left;
}

#msgRightPane {
    float: left;
    width: 480px;
}

#msgLeftPane {
    float: left;
}

#histBox {
    clear: left;
}

/* mbox.php msg display */
#msgBody, .mhBody {
    color: #333;
    font-size: 12px;
    word-wrap: break-word;
    font-family: Tahoma, Helvetica, sans-serif, Arial;
    max-width: 450px;
    overflow: hidden;
}

.msgBodyTxt {
    max-width: 400px;
}

.msgBodyTxt img {
    max-width: 400px;
    max-height: 500px;
}

.msgBodyTxt embed {
    max-width: 400px;
}

.msgBodyTxt .lastOnline {
    margin-top: 5px;
    clear: both;
    font-size: 10px;
}

#msgBody h2 {
    font-size: 14px;
    font-weight: bold;
    color: #666;
    border: 0;
}

#msgArrows {
    float: right;
}

.msgBodyStats {
    font-size: 10px;
    color: #999;
    margin-bottom: 5px;
    float: right;
    font-weight: normal;
    margin-top: 5px;
    margin-right: 20px;
}

#histlink {
    margin: 4px 0;
    display: block;
}

.msgModWarn {
    padding: 5px;
    margin: 5px 0;
    background: #FFEDF3;
    font-size: 12px;
    border: 2px solid #FF75B6;
}

.msgModWarn h2 {
    color: #EA324C;
}

.msgModWarn ul {
    padding: 15px 25px 0px 25px;
    color: #333;
    font-family: 'Courier New';
}

.msgModWarn li {
    margin-bottom: 10px;
}

.MsgTAExp {
    height: 350px;
}

.MsgTA {
    margin: 5px auto;
    width: 475px;
}

.MsgTAClp {
    height: 50px;
}

#msgHead {
    background: #eee;
    border-top: 1px solid #999;
    border-bottom: 1px solid #999;
    clear: both;
    overflow: hidden;
    padding: 5px 10px;
}

#msgHead h1, #msgHead h1 a {
    color: #666;
}

#msgScamWarn {
    background: #ffd5d5 url(@public/images/icon-error-20.png) no-repeat 5px 50%;
    border: 1px solid #ffa2a2;
    padding: 10px 10px 10px 30px;
    width: 370px;
    float: left;
    margin: 20px 15px 0 0;
}

#msgWarnAd {
    margin: 0 auto;
    overflow: hidden;
    width: 615px;
}

#msgWarnAd .botRectAd {
    clear: none;
    float: left;
}

#msgSenderPhoto {
    margin-top: 15px;
    margin-right: 25px;
    float: left;
    overflow: hidden;
    padding: 10px 0px 40px 0px;
    background: url(@public/images/postmark-lblu.png) 0px 65px no-repeat;
}

.msgLeftVBAd {
    float: left;
    width: 122px;
    height: 242px;
    clear: left;
    margin-right: 20px;
}

.msgLeftVBAd .adFrameCnt {
    border: 0;
    background: transparent;
}

.female#msgSenderPhoto {
    background: url(@public/images/postmark-pink.png) 0 65px no-repeat;
}

#msgSenderPhoto .thumb {
    border: 1px solid #999;
    padding: 1px;
}

#msgSenderPhoto .user_info {
    color: #000;
}

#msgSenderPhoto .user_info a {
    color: #333;
    text-decoration: underline;
}

#compMsgCont {
    width: 600px;
    margin: 0 auto;
    overflow: hidden;
}

#msg {
    width: 377px;
}

#msgReply {
    margin-top: 5px;
    float: left;
}

#compMsgCont .markItUp, #msgReply .markItUp {
    width: 405px;
}

#msgReply .markItUp {
    margin: auto;
}

.pm_announce {
    color: #444;
    background: #eee;
    font-weight: bold;
    border: 1px solid #999;
    padding: 8px 20px;
    width: 550px;
    margin: auto;
    text-align: center;
}
