* {
    margin: 0;
    padding: 0;
}

body {
    background: #D0E1FF url(@public/images/bg2.png) 0 0 repeat-x;
    margin: 0;
    word-wrap: break-word;
}

body, p, td, .normalText, .normalText li, .normalText ul {
    font-family: Tahoma, Helvetica, sans-serif, Arial;
    font-size: 12px;
    color: #222;
    text-decoration: none;
}

/* slightly enhanced, universal clearfix hack */
.clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}

.clearfix {
    display: inline-block;
}

/* start commented backslash hack \*/
* html .clearfix {
    height: 1%;
}

.clearfix {
    display: block;
}

/* close commented backslash hack */

#page {
    width: 90%;
    min-width: 1000px;
    max-width: 1200px;
    width: expression(document.body.clientWidth > 1200 ? "1200px": "auto" );
    width: expression(document.body.clientWidth < 1000 ? "1000px": "auto" );
    margin: 4px auto;
}

.normalText p {
    margin: 5px 0;
}

form {
    margin: 0px;
    padding: 0px;
}

form label {
    color: #666;
    font-weight: bold;
    font-size: 11px;
}

label {
    color: gray;
}

td.label, td {
    text-align: left;
}

ul.plain {
    list-style-image: none
}

a, .clickable {
    color: #6088C8;
    text-decoration: none;
    outline: none; /* firefox messes layout up */
}

.clickable {
    cursor: pointer;
}

a:visited {
    color: #6088C8;
}

a:active {
    color: #00f;
}

a:hover,
.clickable:hover {
    color: #00f;
}

.female,
a.female {
    color: #C800BD;
    white-space: nowrap;
}

.male,
a.male {
    color: #006AFF;
    white-space: nowrap;
}

a.female-dark {
    color: #a63ba0;
    white-space: nowrap;
}

a.male-dark {
    color: #1a60c2;
    white-space: nowrap;
}

img {
    border: 0;
    vertical-align: middle;
}

h1 {
    margin: 0px;
    padding: 0px;
    font-size: 14px;
    color: #005DE5;
}

h2 {
    font-size: 12px;
    margin: 0px;
    margin-bottom: 3px;
    padding: 3px;
    color: #669;
    border-bottom: 1px solid #D8DFEA;
}

h3 {
    color: #999;
    font-size: 11px;
    font-weight: bold;
    margin: 0px;
    font-weight: bold;
    margin-bottom: 3px;
    padding: 3px;
    color: #669;
}

h1, h2, h3, h4, h5, h6 {
    font-family: Verdana;
}

strong, em, b, i {
    font-family: Verdana;
}

hr {
    border: 0;
    width: 100%;
    color: #dde;
    background-color: #dde;
    height: 4px;
    margin: 3px 0px 3px 0px;
}

#mc_box {
    text-align: center;
    min-width: 1000px;
    max-width: 1200px;
    width: expression(document.body.clientWidth > 1200 ? "1200px": "auto" );
    width: expression(document.body.clientWidth < 1000 ? "1000px": "auto" );
    margin: 0;
}

#gradBgBox {
    clear: left;
    overflow: hidden;

    text-align: left;
    padding: 0;
    width: 100%;
    min-width: 1000px;
    background: #fff url(@public/images/grad-fff-E3ECFF-15.png) 0 0 repeat-x;
}

#gradBgBox.gry {
    padding-top: 3px;
    border-top: 1px solid #fff;
    background: #fff url(@public/images/grad-fff-ddd-15.png) 0 0 repeat-x;
}

#mainContainer {
    font-family: Tahoma, Helvetica, sans-serif, Arial;
    margin: 0;
    padding-top: 5px;
    overflow: hidden;
}

#bodyFlex {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
}

#bodyFlex .infoPageMain {
    margin: 0;
}

#mainContWBar {
    /* centered fixed-width main content box for use when we also have a wide right bar */
    min-width: 620px;
    width: expression(document.body.clientWidth > 620 ? "620px": "auto" );
    overflow: hidden;
    margin: 0 auto;
}

#main_title {
    overflow: hidden;
    margin: 0;
    text-align: center;
    background: #6BA4F1 url(@public/images/t5bg.jpg) repeat-x;
    padding: 0;
    border-bottom: 3px solid #fff;
    height: 46px;
}

.white_links a {
    color: #fff;
}

.white_links a:visited {
    color: #fff;
}

.si_welcome {
    background: #A4C8EB;
    border-bottom: 2px solid #6FA6DD;
    padding: 5px;
    margin-bottom: 5px;
    overflow: hidden;

}

.normalText {
    font-family: Tahoma, Helvetica, sans-serif, Arial;
    font-size: 11px;
    color: #000000;
    text-decoration: none;
}

.rbox_main {
    text-align: left;
    color: #666;
    border-bottom: 0;
    border-top: 0;
    text-align: left;
    overflow: none;
}

.rboxSub {
    width: 336px;
    overflow: none;
    background: #f9f9f9;
    padding: 5px 0;
}

.rboxSub h2 {
    font-weight: normal;
    font-size: 12px;
    border: 0;
    padding: 0;
    margin: 0;
    color: #333;
    margin-left: 5px;
}

.inputtext, .inputpassword {
    border: 1px solid #bdc7d8;
    padding: 3px;
    font-size: 11px;
    font-family: Tahoma, Helvetica, sans-serif, Arial;
}

input[type="checkbox"], input[type="radio"] {
    margin: 3px;
}

input[type="text"],
input[type="password"] {
    border: 1px solid #bdc7d8;
    padding: 3px;
    font-size: 11px;
    font-family: Tahoma, Helvetica, sans-serif, Arial;
}

textarea {
    border: 1px solid #bdc7d8;
    padding: 3px;
    font-size: 11px;
    font-family: Tahoma, Helvetica, sans-serif, Arial;
}

button img {
    vertical-align: text-bottom;
}

.f_left {
    float: left;
}

.f_right {
    float: right;
}

button,
input[type="submit"],
input[type="button"] {
    border: 1px solid #6e89bc;
    padding: 3px 10px 3px 10px;
    margin: 3px;
    text-align: center;
    color: #222;
    font-size: 12px;
    line-height: 16px;
    font-family: Tahoma, Helvetica, sans-serif, Arial;;
    background: #fff url(@public/images/sbbg3.png) center center repeat-x;
    border-radius: 5px;
}

button:hover,
input[type="submit"]:hover,
input[type="button"]:hover {
    border: 1px solid #336FAA;
    background: #fff url(@public/images/sbbg4.png) center center repeat-x;
}

button:active,
input[type="submit"]:active,
input[type="button"]:active {
    border: 1px solid #34475A;
    background: #ccc;
}

input[type~="radio"] {
    border: 0;
}

select {
    border: 1px solid #bdc7d8;
    padding: 2px;
    font-size: 11px;
    font-family: Tahoma, Helvetica, sans-serif, Arial;
}

input#login, input#password, input#email, input#password1, input#old_password,
input#password2, input#username,
input#skype, input#icq, input#aim, input#yahoo, input#msn {
    padding-left: 14px;
    background-repeat: no-repeat;
    background-position: 2px center;
}

input#login, input#username, input#skype, input#icq, input#aim, input#yahoo, input#msn {
    background-image: url(@public/images/icons/user-10.png);

}

input#password, input#password1, input#password2, input#old_password {
    background-image: url(@public/images/icons/lock-10.png);
}

input#email {
    background-image: url(@public/images/icons/email-10.png);
}

INPUT[type="text"][readonly],
INPUT[type="password"][readonly],
TEXTAREA[readonly],
SELECT[readonly] {
    background-color: #F0F0F0;
    color: #777777;
}

.yellowFooter {
    background: #92BAFF;
    color: #cbedfc;
    padding: 5px;
    text-align: center;
}

.yellowFooter a {
    color: #cbedfc;
    padding-left: 10px;
    padding-right: 10px;
}

.yellowFooter a:hover {
    color: #629AE0;
}

#bluebar_table {
    margin-top: 0;
    border-top: 1px solid #fff;
    min-width: 1000px;
    clear: both;
    width: 100%;
    height: 29px;
}

#topNav {
    clear: left;
    width: 100%;
    background: #a9d2ff url(@public/images/tn_bg.png) 0 100% repeat-x;
    height: 27px;
    padding: 0;
    border-bottom: 1px solid #fff;
    overflow: hidden;
}

#tn_cont2 {
    float: left;
    left: 50%;
    position: relative;
}

#tn_cont {
    text-align: center;
    overflow: hidden;

    margin: auto;
    position: relative;
    float: left;
    left: -50%;
}

#topNav a {
    border: 0;
    margin: 0;
    font-size: 14px;
    font-weight: bold;
    padding: 2px 15px 0px 15px;
    color: #fff;
    display: block;
    float: left;
    height: 24px;
    line-height: 18px;
    font-weight: normal;
    font-family: verdana;
}

.bb {
    width: 22px;
    height: 22px;
    overflow: hidden;
    margin-right: 5px;
    float: left;
}

.bb_cont {
    background: transparent url(@public/images/bb-spr.png) no-repeat 4px 0;
}

* html .bb_cont {
    background: transparent url(@public/images/bb-spr.gif) no-repeat 4px 0;
}

#bb1 {
    background-position: 0px 0px;
}

#bb2 {
    background-position: 0px -23px;
}

#bb3 {
    background-position: 0px -46px;
}

#bb4 {
    background-position: 0px -69px;
}

#bb5 {
    background-position: 0px -92px;
}

#bb6 {
    background-position: 0px -115px;
}

*:first-child + html #topNav a {
    height: 24px;
    padding-top: 2px;
    margin: 0;
}

* html #topNav a {
    padding-top: 2px;
    height: 24px;
    margin: 0;
}

#topNav a:hover {
    background: #7EB6F8 url(@public/images/tn_bg2.png) 0 100% repeat-x;
    border: 0;
    color: #fff;
}

#topNav a:active {
    color: #BAD5F0;
}

.required {
    color: #f00;
}

.rep_msg {
    margin-left: 152px;
    width: 300px;
    word-wrap: break-word;
    overflow: hidden;
    color: #6088C8;
}

.photo_prof {
    float: left;
    margin: 1px;
    padding: 2px;
    text-align: center;
    overflow: hidden;
}

.photo_prof .status {
    margin-right: 3px;
    vertical-align: middle;
}

.photo_prof .location {
    margin-top: 3px;
}

.photo_prof .location img {
    margin-right: 2px;
}

.photo_prof .thumb {
    margin: 3px;
}

.photo_prof .user_info {
    color: #888;
    width: 106px;
    overflow: hidden;
}

.online_prof a {
    font-size: 12px;
}

/* pagination */
.pagination {
    color: #666;
    padding: 7px;
}

.pages { /* inner box */
    overflow: hidden;
    display: inline;
}

.pages a {
    border: 1px solid #ccc;
    padding: 3px;
    font-weight: bold;
    margin: 0 3px 0 3px;
    background: #fff;
}

.pages a:hover {
    border: 1px solid #239CFB;
    background: #D5EBF9;
}

a.cur_page {
    border: 1px solid #0060E6;
    background: #dce8f8;
    color: #0060E6;
}

a:hover.cur_page {
    text-decoration: none;
}

.showingx {
    padding-top: 10px;
    color: #666;

}

.boxHead, .boxHeadG {
    width: 565px;
    display: block;
    overflow: hidden;

}

.boxHeadCont, .boxHeadContF {
    overflow: hidden;

}

.boxHeadContF {
    float: left;
}

.boxHeadCont .boxText, .boxHeadContF .boxText {
    clear: left;
}

.boxHead h1, .boxHeadG h1 {
    font-size: 14px;
    font-weight: bold;
    font-family: 'Helvetica Neue';
    color: #666;
    font-size: 24px;
    line-height: 24px;
    padding: 5px 10px;
}

.topRnd {
    /* top two corners are rounded */
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    -moz-border-radius-topright: 12px;
    -moz-border-radius-topleft: 12px;
}

.allRnd {
    /* all corners are round */
    border-radius: 6px;
    -moz-border-radius: 6px;
}

.boxHeadG {
    background: #eee;
    border: 1px solid #bbb;
}

.boxHeadG h1 {
    color: #444;
    background: #eee;
}

.boxHead h1 a, .wall h1 a {
    color: #3A81B0;
}

h1 img {
    vertical-align: middle;
}

.bhThumbBox {
    overflow: hidden;
    margin-bottom: 3px;
    min-height: 75px;
}

.bhThumbBox .boxHead {
    overflow: hidden;

    width: 465px;
    float: left;
}

.bhThumbBox .headThumb {
    float: left;
    margin-right: 5px;
    text-align: center;
}

bhThumbBox.photoTop {
    padding-right: 0;
    height: 58px;
    min-height: 58px;
    margin: 0 auto;
    width: 700px;
}

.bhThumbBox .boxText {
    float: left;
}

.boxText, .boxText p {
    color: #555;
    line-height: 16px;
    font-size: 12px;
    margin-top: 5px;
    max-width: 565px;
    padding: 10px;
    padding-bottom: 0;
    color: #5a5a5a;
}

.boxText p {
    padding-left: 5px;
    padding-right: 5px;
}

.box {
    background: #fff;
    padding: 5px;
    width: 563px;
    border: 1px solid #ccc;
    border-top: 0;
    overflow: hidden;

}

.box h1 {
    color: #555;
}

.topLogo {
    float: left;
    overflow: hidden;
    text-align: center;
    height: 100px;
}

.shortTop .topLogo {
    height: 65px;
}

* html .topLogo {
    height: 100px;
}

.leftWelcome {
    float: left;
    margin-left: 5px;
    margin-right: 15px;
    font-family: verdana;
    padding-top: 4px;
}

.topWelcome {
    font-size: 11px;
    font-family: verdana;
    margin-top: 3px;
    color: #333;
    text-align: left;
    padding-left: 25px;
}

.rightWelcome {
    float: left;
    text-align: left;
    overflow: hidden;
}

.memberLinks {
    overflow: hidden;
    margin: auto;
    float: left;
}

.rightWelcome a, .memberLinks a {
    font-weight: bold;
    color: #1378E5;
    overflow: hidden;
    font-weight: normal;

}

.memberLink {
    float: left;
    display: block;
    text-align: left;
    border-left: 1px solid #ffff95;
    border-right: 1px solid #ffff95;
    padding: 4px 10px 5px 0;
}

a.memberLink, a.memberLink:visited {
    color: #666;
    font-family: Verdana;
    font-size: 12px;
    font-weight: bold;
}

a.memberLink:hover {
    background: #fff url(@public/images/refgradbg-y.png) 0 center repeat-x;
    border-color: #ccc;
}

.topMemberNav {
    float: left;
    padding: 0;
    width: 100%;
    color: #005DE5;
    background: #FFFF95;
    height: 25px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    -moz-border-radius-topright: 8px;
    -moz-border-radius-topleft: 8px;

}

a.memberLinkEnd {
    padding-right: 0;
}

.topMemberNav h4 {
    color: #3F9A78;
    font-weight: bold;
}

.yb_cont {
    background: url(@public/images/yb-spr.png) no-repeat 0 0;
    width: 30px;
    height: 16px;
    overflow: hidden;
    float: left;
}

* html .yb_cont {
    /* degrade semi-gracefully for ie6 */
    background: transparent url(@public/images/yb-spr.gif) no-repeat 4px 0;
}

#yb8 {
    background-position: 10px -1px;
}

#yb9 {
    background-position: 10px -18px;
}

#yb5 {
    background-position: 10px -35px;
}

#yb10 {
    background-position: 10px -52px;
}

#yb6 {
    background-position: 10px -69px;
}

#yb4 {
    background-position: 10px -86px;
}

#yb1 {
    background-position: 10px -103px;
}

#yb7 {
    background-position: 10px -120px;
}

#yb2 {
    background-position: 10px -137px;
}

#yb3 {
    background-position: 10px -154px;
}

.rbox_main .exp, .rbox_main .cont {
    width: 14px;
    height: 14px;
    margin-right: 5px;
    float: left;
}

.rbox_main .exp {
    /*   background: url(@public/images/icons/tq-arr-down.png); */
}

.rbox_main .cont {
    /*background: url(@public/images/icons/tq-arr-side.png); */
}

#signinBox #login, #signinBox #password {
    width: 200px;
}

.normalText {
    font-size: 12px;
    font-weight: normal;
    line-height: 18px;
}

.loginExplain {
    color: #333;
}

.bracketLink {
    display: block;
    font-size: 11px;
    color: #425D85;
    text-align: right;
}

.fieldExplain {
    font-size: 10px;
    color: #777;
}

.tzLink {
    color: #4D9CCE;
}

.underlink, .underlink_inline {
    color: #6083a6;
    font-size: 11px;
    text-decoration: underline;
    display: block;
}

.underlink {
    clear: both;
}

.introBlurb a {
    font-weight: bold;
}

.littleFlag {
    margin-right: 5px;
}

.formExplain {
    font-size: 10px;
    color: #999;
}

.nobreak {
    white-space: nowrap;
}

img.langFlag {
    margin-right: 4px;
}

.yTitle {
    color: #ffff99;
    font-size: 12px;
    font-weight: bold;
    color: #666;
    padding: 5px;
    overflow: hidden;

}

.yTitle .loadInto {
    padding: 4px 4px 4px 0;
    margin-right: 5px;
}

.yTitle .arrow {
    height: 20px;
    width: 20px;
    float: left;
    background: url(@public/images/small-gray-rarr.png) 5px 5px no-repeat;
}

.yTitle .arrow.down {
    background: url(@public/images/small-gray-darr.png) 5px 5px no-repeat;
}

.collapsed {
    padding-left: 18px;
    background: url(@public/images/small-gray-rarr.png) 5px 5px no-repeat;
}

.expanded {
    padding-left: 18px;
    background: url(@public/images/small-gray-darr.png) 5px 5px no-repeat;
}

.greenForm {
    padding: 15px;
    line-height: 19px;
    border: 10px solid #fff;
}

.greenForm table {
    margin: 0;
    padding: 0;
}

.greenForm td {
    vertical-align: top;
    padding-left: 10px;
}

.greenForm p {
    margin-bottom: 10px;
}

.greenForm label {
    font-size: 12px;
}

.infoBox {
    background: #eee;
    padding: 10px;
    width: 450px;
    border: 1px solid #666;
    margin: 10px 20px;
}

.qsForm {
    line-height: 20px;
    padding: 10px;
    margin: 5px;
    background: #eee;
    width: 160px;
    overflow: hidden;
    float: left;
}

.qsLinks {
    line-height: 20px;
    padding: 10px;
    margin: 5px;
    background: #eef;
    width: 110px;
    overflow: hidden;
    float: left;
}

.myIP {
    background: #eee;
    padding: 3px;
    width: 336px;
}

.rUsers {
    width: 355px;
    margin: 0 auto;
}

.rUsers .boxHead, .myIP .boxHead {
    width: auto;
}

.langexUsers, .langexUsers h1 {
    background: #fff;
    color: #30B0DF;
}

.randUsers, .randUsers h1 {
    background: #fff;
    color: #1BBB69;
    padding: 0;
    font-size: 16px;
    font-weight: normal;
}

.randUsers h1 a, .randUsers h1 a:visited {
    color: #1BBB69;
}

.rUserBox {
    overflow: hidden;
    margin-bottom: 3px;
}

.poster_avatar {
    width: 140px;
    margin: auto;
    overflow: hidden
}

.poster_avatar .photo_prof {
    float: none;
}

.rUsers .photo_prof, #bmrBox .photo_prof, #frBox .photo_prof, .acctTip .photo_prof {
    min-width: 10px;
    width: 112px;
    padding: 0;
    margin: 0;
    overflow: hidden;
    font-size: 9px;
    text-transform: uppercase;
    white-space: nowrap;
}

.rUsers .photo_prof {
    width: 114px;
}

.rUsers .underlink {
    margin: 4px;
}

.uSetNextArr {
    float: right;
    display: block;
    overflow: hidden;
    height: 72px;
    background: url(@public/images/icons/next-bl-12.png) 0 50px no-repeat;
    width: 12px;
    margin-right: 3px;
    display: none;
}

.uSetNextArr:hover {
    background-color: #eef;
}

.uSetNextArr:active {
    background-color: #ccc;
}

.lxUser .uSetNextArr {
    background-position: 0 30px;
}

#wideRightBar,
#profRightBar,
#narrowRightBar {
    float: right;
    margin: 1px 1px 1px 8px;
    overflow: hidden;
    clear: right;
    overflow: none;
}

#narrowRightBar {
    width: 160px;
    min-height: 600px;
    margin: 0;
}

#topBar {
    margin: 0 auto;
    overflow: hidden;
    height: 24px;
}

#topBarChild {
    clear: left;
    padding-left: 194px;
    overflow: hidden;
    float: left;

}

#topBarLinks {
    text-align: right;
    color: #fff;
    width: 194px;
    background: #CCBBF8;
    float: left;
    padding: 2px;
    text-align: center;
    margin-right: 10px;
}

#topBarLinks a {
    color: #999;
    font-size: 10px;
    color: #fff;
}

#addThis {
    float: left;
}

#logoAdBar {
    clear: both;
    overflow: hidden;
    height: 100px;
    margin: 0 auto;
}

#logoAdBar.withLogin {
    width: 850px;
}

#logoAdBar.shortTop {
    height: 65px;
}

.topLinkAds {
    margin-bottom: 10px;
}

.botRectAd {
    /* main content bot rect ad */
    width: 338px;
    height: 282px;
    margin: 0 auto;
    margin-top: 20px;
}

.rbRectAd { /* right bar top rect ad */
    width: 338px;
    margin-bottom: 15px;
    overflow: auto;
}

.rbMedRectAd { /* right bar top rect ad */
    width: 302px;
    height: 252px;
    margin: 0 0 10px 20px;
}

.rbBotRectAd { /* right bar bottom rect ad */
    margin-top: 25px;
}

.inlRectAd {
    text-align: center;
    margin: 10px 0 10px 0;
}

.topLB {
    height: 92px;
    width: 730px;
    margin: auto;
    overflow: hidden;
}

.shortTop .topLB {
    width: 468px;
    height: 60px;
}

.rbBotSky {
    padding-top: 10px;

    overflow: hidden;
    float: left;
}

.notYou {
    color: #888;
    font-size: 11px;
    line-height: 14px;
}

.bigLink {
    font-size: 18px;
}

.medLink {
    font-size: 14px;
}

/* members.css */
#bmrBox, #frBox {
    padding: 0;
}

#chatStatus {
    float: left;
    background-color: #E8FFF5;
    border: 1px #B3F8DA solid;
    color: #666;
    padding-left: 5px;
    margin-right: 10px;
}

.wait {
    background: url(@public/images/icons/wait-22.gif) no-repeat;
    padding: 5px;
    padding-left: 25px;
}

#ssrch {
    padding: 10px;
    margin: 5px;
}

#membStats {
    padding: 5px;
    margin: 5px;
    font-size: 12px;
}

#membStats a {
    font-weight: bold;
}

.display_none {
    display: none;
}

.ajx_load {
    font-size: 12px;
    color: #666;
    background: url(@public/images/prog/circ-16.gif) 5px 5px no-repeat;
    display: block;
    padding: 5px 5px 5px 30px;
}

.ajx_err {
    font-size: 11px;
    background: url(@public/images/icon-error.png) 5px 50% no-repeat;
    padding: 5px 5px 5px 30px;
}

.ajx_circ_16 {
    background: url(@public/images/prog/circ-16.gif) no-repeat;
}

.ajx_err b {
    color: #f00;
}

.closeable .closer {
    float: right;
    cursor: pointer;
    width: 10px;
    height: 10px;
    margin: 5px 5px 0 0;
    background: url(@public/images/close-10.png) no-repeat;
}

.newAlert {
    color: #F22571;
    font-size: 11px;
    text-decoration: none;
}

#topLogin {
    font: 14px/20px "Helvetica Neue", sans-serif;
    float: right;
    margin: 10px 0 0 0;
}

#topLogin td {
    padding-left: 10px;
}

#topLogin label, #topLogin a, #topLogin input {
    font: 14px/20px "Helvetica Neue", sans-serif;
}

#topLogin input[type="submit"], #suBox input[type="submit"] {
    color: #fff;
    background: #7abcff; /* Old browsers */
    background: -moz-linear-gradient(top, #7abcff 0%, #60abf8 44%, #4096ee 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #7abcff), color-stop(44%, #60abf8), color-stop(100%, #4096ee)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #7abcff 0%, #60abf8 44%, #4096ee 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #7abcff 0%, #60abf8 44%, #4096ee 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #7abcff 0%, #60abf8 44%, #4096ee 100%); /* IE10+ */
    background: linear-gradient(to bottom, #7abcff 0%, #60abf8 44%, #4096ee 100%); /* W3C */
}

#topLogin input[type="submit"]:hover, #suBox input[type="submit"]:hover {
    background: #1e5799; /* Old browsers */
    background: -moz-linear-gradient(top, #1e5799 0%, #2989d8 50%, #207cca 51%, #7db9e8 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #1e5799), color-stop(50%, #2989d8), color-stop(51%, #207cca), color-stop(100%, #7db9e8)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #1e5799 0%, #2989d8 50%, #207cca 51%, #7db9e8 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #1e5799 0%, #2989d8 50%, #207cca 51%, #7db9e8 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #1e5799 0%, #2989d8 50%, #207cca 51%, #7db9e8 100%); /* IE10+ */
    background: linear-gradient(to bottom, #1e5799 0%, #2989d8 50%, #207cca 51%, #7db9e8 100%); /* W3C */
}

.sqr_progbar {
    background: #fff url(@public/images/prog/sqr-bl-43x11.gif) 5px 2px no-repeat;
    min-height: 14px;
}

.progbar_center {
    background: transparent url(@public/images/prog/sqr-bl-43x11.gif) center center no-repeat;
}

.boxTitle h1, .boxTitle a .boxTitle h1 a {
    color: #555;
    border: 0;
    overflow: hidden;
}

.outlinedBox {
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 12px;
    -moz-border-radius: 12px;
    overflow: hidden;

}

/* For centering variable-width float-boxes. */
.cCont {
    overflow: hidden;
}

.cOut {
    float: left;
    position: relative;
    left: 50%;
}

.cIn {
    float: left;
    position: relative;
    left: -50%;
}

#browserWarning {
    display: none;
}

.editorLink {
    display: none;
    font-size: 11px;
    color: #444;
    text-decoration: underline;
    padding: 1px 0;
}

.grayInput {
    color: #999;
}

.nograyInput {
    color: black;
}

/* Forum post lists */
.content ul, .content ol {
    padding-left: 20px;
}

.fgButton {
    outline: 0;
    margin: 0 4px 0 0;
    padding: .4em 1em;
    text-decoration: none !important;
    cursor: pointer;
    position: relative;
    text-align: center;

}

.bottomNav {
    text-align: left;
    background: #b8d0fa;
}

.bottomNavCont {
    width: 970px;
    margin: auto;
    padding-top: 15px;
}

.bottomNav .bnavSection {
    float: left;
    overflow: hidden;
    padding: 0 20px;
}

.bnavSection a {
    display: block;
    color: #444;
    line-height: 18px;
}

.bnavSection a:hover {
    color: #999;
}

.bnavSection h2 {
    padding: 5px 0;
    color: #333;
    width: 150px;
    border-bottom: 3px solid #cedefb;
}

.copy {
    width: 925px;
    color: #444;
    height: 20px;
    text-align: center;
    padding: 10px;
    padding-top: 25px;
    clear: both;
}

.copy a {
    color: #666;
}

.infoPageMain {
    width: 600px;
    margin: 0px auto 55px auto;
}

.infoPageMain .boxHead h1 {
    border-bottom: 5px solid #ccc;
}

.infoPageMain h2, .infoPageMain h3 {
    font-weight: bold;
    color: #555;
    font-size: 14px;
}

/* bootstrap-tooltip */

.tooltip {
    position: absolute;
    z-index: 1030;
    display: block;
    padding: 5px;
    font-size: 11px;
    opacity: 0;
    filter: alpha(opacity=0);
    visibility: visible;
}

.tooltip.in {
    opacity: 0.8;
    filter: alpha(opacity=80);
}

.tooltip.top {
    margin-top: -3px;
}

.tooltip.right {
    margin-left: 3px;
}

.tooltip.bottom {
    margin-top: 3px;
}

.tooltip.left {
    margin-left: -3px;
}

.tooltip-inner {
    max-width: 200px;
    padding: 3px 8px;
    color: #ffffff;
    text-align: center;
    text-decoration: none;
    background-color: #000000;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
}

.tooltip.top .tooltip-arrow {
    bottom: 0;
    left: 50%;
    margin-left: -5px;
    border-top-color: #000000;
    border-width: 5px 5px 0;
}

.tooltip.right .tooltip-arrow {
    top: 50%;
    left: 0;
    margin-top: -5px;
    border-right-color: #000000;
    border-width: 5px 5px 5px 0;
}

.tooltip.left .tooltip-arrow {
    top: 50%;
    right: 0;
    margin-top: -5px;
    border-left-color: #000000;
    border-width: 5px 0 5px 5px;
}

.tooltip.bottom .tooltip-arrow {
    top: 0;
    left: 50%;
    margin-left: -5px;
    border-bottom-color: #000000;
    border-width: 0 5px 5px;
}

.mprofRow .tooltip-inner {
    background-color: #0000FF;
}

.mprofRow .tooltip.right .tooltip-arrow {
    border-right-color: #0000FF;
}

/* end bootstrap-css tooltip */
.adLinkCnt {
    text-align: center;
    background: #eee;
    border: 1px solid #ddd;
    border-top: 0;
    font-family: Verdana;
    font-size: 10px;
    clear: both;
    padding: 2px;
}

.adLinkCnt a {
    color: #999;
    text-decoration: underline;;
}

.adFrameCnt {
    overflow: none;
    /*background: #f9f9f9;
    border: 1px solid #ddd;
    */
}

.online-now {
    color: #12be0c;
}

.offline-now {
    color: #666;
}

.input-text-label {
    color: #999;
}

/* To center a variable-width block, use ibCenterWrap
   as outer element, ibCenterInner as inner elem. */
.ibCenterOut {
    text-align: center;
}

.ibCenterIn {
    display: inline-block;
    text-align: left;
}

.page-title-wrap {
    margin: 0 0 0 20px;
    overflow: hidden; /* establish new float context, to extend div and catch floated thumb */
}

.page-title {
    color: #666;
}

.page-title h1 {
    font: bold 22px/22px 'Helvetica Neue' sans-serif;
    color: #666;
    margin: 10px 0 20px 0;
}

.page-title-wrap .thumb {
    float: left;
    margin-right: 10px;
}

.text-filters {
    font-weight: bold;
    padding-left: 20px;
}

.text-filters a {
    font-weight: normal;
}

.xxx {
    border: 1px solid #666;
}

.qc-cmp-button.qc-cmp-secondary-button {
    border-color: #eee !important;
    background-color: #eee !important;
}

.cacheBustV25 {
    display: none;
}

