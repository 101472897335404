#searchCont {
    width: 1000px;
    margin: auto;

}

.sCField label, .sCField .title {
    color: #333;
    font-size: 12px;
}

.sCField label {
    color: #888;
}

.sResCont {
    overflow: hidden;
    clear: left;
    margin: auto;
    background: #f6f6f6;
    margin-bottom: 10px;
    width: 760px;

}

.sResInner {
    overflow: hidden;
    float: left;
    padding: 3px;
    margin: 10px;
}

.sResLeft {
    display: block;
    float: left;
    width: 140px;
}

.sResMain {
    font-size: 12px;
    float: left;
    width: 440px;
    overflow: hidden;
}

.sResSex {
    margin: 0 3px 0 3px;
}

.sResMainTxt {
    overflow: hidden;
}

.sResLastOnline {
    margin-top: 5px;
}

.sResJoined {
    margin-top: 5px;
    color: #999;
}

#sResPaginationBottom {
    width: 800px;
    margin: auto;
}

.sResTxtField {
    margin-top: 10px;
    font-family: verdana;
    font-size: 13px;
    color: #444;
    overflow: hidden;
    height: 100px;
    overflow: none;
    word-wrap: break-word;
}

.sResLinksCont {
    overflow: hidden;
    border-left: 1px solid #ddd;
    margin-left: 5px;
    padding-left: 10px;
    float: left;
    height: 145px;
}

.sResLinks {
    margin-left: 2px;
}

.sResLinks a:hover {
    color: grey;
}

.sResLinks a {
    margin: 15px 0;
    font-size: 12px;
    display: block;
}

.sResLinks a i {
    color: #999;
}

.sResLinks img {
    margin-right: 4px;
}

#srStatMsg {
    color: #00C300;
    overflow: hidden;

}

#srStatMsg h2 {
    color: #999;
    float: left;
    border: 0;
    padding: 0;
    margin-right: 4px;
    font-weight: normal;
}

#srStatMsgBody {
    float: left;
    max-width: 160px;
}

a.sResMale {
    color: #2F74F3;
}

a.sResFemale {
    color: #BE47FF;
}

.sResStats {
    margin-top: 5px;
    text-align: left;
    line-height: 16px;
    clear: both;
    color: #999;
}

/* Search options / controls */
#sCtlBox {
    width: 210px;
    background: #f3f3f3;
    border: 1px solid #ddd;
    margin: 40px 10px 0 0;
    overflow: hidden;
    float: left;
}

.sCField {
    padding: 5px 0 5px 10px;
    margin: 5px 10px 0 10px;
    float: left;
    width: 180px;
    background: #ddd;
    border-radius: 6px;
    -moz-border-radius: 6px;
}

.sCClpsd, .sCExpnd {
    display: none;
}

.sCClpsd .titleTxt {
    text-decoration: underline;
}

.sCClpsd .sCFieldBody {
    display: none;
}

.sCFieldBody {
    padding: 5px 0;
    clear: left;
}

.sCClpsd .title, .sCExpnd .title {
    float: none;
}

#sCBotLinks {
    clear: both;
    margin: 5px;
}

.selMenu {
    display: none;
}

.delSelItem {
    color: #666;
}

.dynSelAdd {
    margin-top: 5px;
}

.selItem {
    margin: 0 5px 5px 0px;
}

#sCMinimized {
    font-size: 12px;
    color: #666;
    font-weight: bold;
    text-align: center;
    display: none;
    overflow: hidden;

}

#sRes {
    margin: auto;
    overflow: hidden;
}

#sResTitle h3 {
    color: #4D9AF9;
    font-size: 18px;
    font-weight: bold;
    margin-right: 10px;
    float: left;
}

#sResTitle {
    margin: 0 auto;
    margin-bottom: 10px;
    width: 750px;
}

.sCField .title, .sCField h3 {
    font-weight: bold;
    color: #666;
}

.sResThumb {
    display: block;
    overflow: hidden;
}

.sSettingsWrapper {
    clear: left;
    float: left;
    margin: 5px 10px;
    padding-top: 5px;
    width: 100%;
    cursor: pointer;
}

.sSaved, .sSettingsWrapper, .sSaving {
    font-size: 12px;
    line-height: 15px;
}

.sSearchBtn {
    margin-left: 58px;
    margin-top: 10px;
    background: #ddd;
    border: 1px solid #ccc;
    width: 80px;
}

.sSaving {
    color: grey;
}

.sSaved {
    color: green;
}

.sSaved, .sSaving {
    display: block;
    float: left;
    margin-left: 5px;
}

.expBtnimg {
    width: 30px;
    height: 20px;
    cursor: pointer;
}

.expBtnimg:hover {
    filter: alpha(opacity=60);
    opacity: 0.6;
}

.expBtnDiv {
    line-height: 17px;
    padding: 3px;
    text-align: center;
    clear: both;
}

#sCSave, #sCReset {
    display: block;
    float: left;
}

#sCReset {
    margin-left: 5px;
}

.sResRight {
    float: left;
}

.easy-autocomplete-container ul li, .easy-autocomplete-container ul .eac-category {
    font-size: 12px;
}
