/* Mod page styles */
.repUserDetails .scam,
#modUserInfo .scam {
    background-color: red;
}

.scam A {
    color: white;
    font-weight: bold;
}

#modHead h1 {
    font-size: 16px;
    color: #333;
}

#modUserLinks {
    float: left;
}

#modUserLinks li {
    list-style-type: none;
}

#modUserLinks a {
    padding: 5px;
    display: block;
    margin-left: 0;
}

#modUserInfo {
    float: left;
    width: 150px;
    overflow: hidden;

}

#modUserForm {
    float: left;
    overflow: hidden;

    background: #eee;
    padding: 10px;
}

#userInfoContainer {
    overflow: hidden;
    float: left;
}

#mFlgd {
    overflow: hidden;
}

.mFlgdPh {
    float: left;
    width: 100px;
    height: 100px;
    text-align: center;
}

.mDups, .acctFlag {
    width: 160px;
    border: 5px solid #eee;
    float: left;
    margin: 5px;
    padding: 3px;
    overflow: hidden;
}

.modNav {
    padding: 8px 0;
    margin-bottom: 5px;
    overflow: hidden;

    color: #555;
    border-bottom: 3px dotted #ddd;
    font-size: 1.1em;
}

.modNav a, .modNav b {
    font-weight: bold;
    margin: 0 5px;
    color: #666;
    text-decoration: underline;
}

.modNav .curPage {
    text-decoration: none;
    color: #333;
}

/* User Report styles */
.userReport {
    overflow: hidden;

    padding: 5px;
    padding-bottom: 10px;
    margin: 5px;
    float: left;
    border-bottom: 5px dotted #eee;
    width: 1000px;
}

.userRepTop {
    color: #666;
    font-family: 'Courier new', courier;
    margin-bottom: 5px;
}

.userRepLeft {
    overflow: hidden;

    float: left;
    width: 135px;
    margin-right: 10px;
}

.repUserInfo {
    text-align: center;
    margin: 2px;
}

.rUI_title {
    color: #666;
    margin: 0;
    padding: 0;
}

.userReports .photo_prof {
    width: 120px;
    margin-bottom: 3px;
    float: none;
}

.userReports ul {
    list-style-position: inside;
    list-style-type: disc;
}

.userReport .modActions {
    float: left;
    width: 215px;
    margin: 0 15px;
}

.userReport .logMsg .logMsgTA {
    width: 180px;
    height: 20px;
    margin: 0 0 5px 2px;
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: hidden;
    color: #000;
}

.userReport .modLogPrev {
    float: left;
    width: 200px;
}

.modLogPrev .logEntry {
    border: 1px solid #0cf;
    border-radius: 5px;
    -moz-border-radius: 5px;
    padding: 5px;
    margin-top: 5px;
    color: #666;
}

.logEntryBody {
    font-family: 'Courier new', courier;
    margin-top: 7px;
    color: #069;
    font-weight: bold;
    font-size: 1.1em;
}

.userReport .reportData {
    float: left;
    width: 300px;
}

.indivUserRep {
    margin: 3px 0;
    line-height: 18px;
    font-size: 12px;
    background: #f6f6f6;
    border: 1px solid #ccc;
    border-radius: 5px;
    -moz-border-radius: 5px;
    padding: 5px;
    word-wrap: break-word;
    overflow: hidden;
}

.indivUserRep button.mod_photo {
    margin-left: 10px;
}

.indivUserRep a {
    color: #09a;
}

.userRepIps {
    margin-top: 10px;
    font-family: 'courier new', courier;
}

.userRepMMIP {
    color: #f00;
    font-weight: bold;
}

.repReason {
    color: #285719;
    padding: 3px;
    border: 1px solid #0cf;
    background: #E6FAFF;
}

.repMsg {
    color: #000;
    font-family: 'Courier New', 'Courier';
    border: 1px solid #f9c;
    border-radius: 5px;
    -moz-border-radius: 5px;
    background: #FFF0F8;
    padding: 5px;
}

.repReasonBody {
    font-family: 'Courier New', 'Courier';
}

#modUser {
    overflow: hidden;
}

.userModActions {
    margin-left: 10px;
    float: left;
    overflow: hidden;
}

#modUser .logMsgTA {
    width: 220px;
    height: 160px;
    margin: 5px 0;
}

.userReportActions {
    background: #fff;
    padding: 5px;
    margin-top: 5px;
}

.repTime {
    font-size: 10px;
    color: #999;
}

.repIP {
    font-size: 10px;
    color: #666;
}

.modRecentMsgs {
    width: 600px;

    overflow: none;
}

.modLogTable {
    border-spacing: 0;
}

.modLogTable th {
    background: #ddd;
}

.modLogTable td,
.modLogTable th {
    padding: 3px 15px;
    text-align: left;
    vertical-align: top;
    font-size: 12px;
    font-family: verdana;
    color: #555;
    border-bottom: 1px dashed #999;
}

.modLogUnameLinks,
.modLogUnameLinks a {
    color: #99c;
}

.modLogUnameLinks .user {
    color: #555;
    font-size: 13px;
    font-weight: bold;
}

.reportsTop {
    margin-bottom: 10px;
    padding: 5px;
    overflow: hidden;
}

.reportsTop .options {
    float: left;
    line-height: 20px;
    margin-right: 35px;
}

.reportsTop .options a {
    color: #005DE5;
    text-decoration: underline;
}

.acctFlag {
    width: 220px;
}

.su_llogin {
    color: #666;
    font-size: 9px;
    margin-bottom: 3px;
}

.mScreenHead {
    overflow: hidden;

}

.mScreenHead div {
    float: left;
}

.mFlagLinks {
    text-align: center;
    clear: left;
}

.mod_action {
    margin-bottom: 5px;
}

.mod_actions_sel {
    width: 210px;
    font-size: 13px;
}

.modRedBold {
    color: red;
    font-weight: bold;
}

.modNormal {
    color: black;
    font-weight: normal;
}