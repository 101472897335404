.noteFoot {
    color: #999;
    border-top: 1px solid #ccc;
    overflow: hidden;
    margin-bottom: 5px;
}

.noteCmtCount {
    float: left;
    color: #666;
}

.noteReportLink {
    float: right;
}

.noteHead {
    background: #eee;
    border-top: 1px solid #999;
    border-bottom: 1px solid #999;
    padding: 5px 10px;
    margin-bottom: 5px;
}

.noteSubj {
    font-size: 13px;
    font-weight: bold;
    padding: 4px 0 0 25px;
    background: transparent url(@public/images/icons/note-16.png) 0 50% no-repeat;
    display: block;
    overflow: hidden;
    height: 24px;
    margin: 5px 0 3px 0px;
}

.notePosted {
    color: #666;
    padding-left: 25px;

}

.noteBody {
    padding: 10px;
    width: 500px;
    word-wrap: break-word;
    margin: 10px auto;

    font-family: Verdana, sans-serif;
    font-size: 12px;
    line-height: 16px;
    color: #333;

}

.note {
    overflow: hidden;
}

.noteCompact {
    padding: 5px;
    padding-left: 25px;
    background: transparent url(@public/images/icons/note-16.png) 0 5px no-repeat;
}

.noteCompactSubj {
    font-size: 12px;
    text-decoration: underline;
    display: block;
    margin-bottom: 3px;
}

.noteCompactInfo {
    font-size: 11px;
    color: #999;
}

.noteEditDel {
    float: right;
    overflow: hidden;
    min-width: 70px;
    padding: 2px;
    line-height: 16px;
}
